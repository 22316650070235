<div style="{{styleBox}}" *ngIf="!type && !link">
    <video *ngIf="helper.getFileFormat(img) === 'video' && (show == true || showPlay == false)" class="{{class}}" playsinline autoplay [muted]="'muted'" loop [src]="img" style="{{styleImage}}"></video>
    <img *ngIf="helper.getFileFormat(img) === 'video' && show == false && showPlay == true" class="{{class}}" [src]="preview" style="{{styleImage}}">
    <a *ngIf="helper.getFileFormat(img) === 'video' && showPlay == true" class="play" (click)="showVideo()" >
        <img *ngIf="show == false" src="/assets/icons/play.png">
        <img *ngIf="show == true" src="/assets/icons/pause.png">
    </a>

    <img *ngIf="helper.getFileFormat(img) === 'image'" class="{{class}}" style="{{styleImage}}" [src]="preview">
</div>

<div style="{{styleBox}}" *ngIf="!type && link">
    <video [routerLink]="[link]" *ngIf="helper.getFileFormat(img) === 'video' && (show == true || showPlay == false)" class="{{class}}" playsinline autoplay [muted]="'muted'" loop [src]="img" style="{{styleImage}}"></video>
    <img [routerLink]="[link]" *ngIf="helper.getFileFormat(img) === 'video' && show == false && showPlay == true" class="{{class}}" [src]="preview" style="{{styleImage}}">
    <a *ngIf="helper.getFileFormat(img) === 'video' && showPlay == true" class="play" (click)="showVideo()" >
        <img *ngIf="show == false"src="/assets/icons/play.png">
        <img *ngIf="show == true"src="/assets/icons/pause.png">
    </a>

    <img [routerLink]="[link]" *ngIf="helper.getFileFormat(img) === 'image'" class="{{class}}" style="{{styleImage}}" [src]="preview">
</div>

<div style="{{styleBox}}" *ngIf="type">
    <video *ngIf="type === 'video'" class="{{class}}" playsinline autoplay [muted]="'muted'" loop [src]="preview" style="{{styleImage}}"></video>
    <img *ngIf="type === 'image'" class="{{class}}" style="{{styleImage}}" [src]="preview">
</div>