// import 'rxjs/add/operator/map';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import Swal from 'sweetalert2';

import { environment } from '../../environments/environment';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  public loading: boolean = false;
  public loadingStack: any[] = [];
  public authToken: any;
  
  constructor(
    private http: HttpClient,
    private storage: StorageService,
    ) {
      
    }
    init() {
      console.debug('init start: apiService');
      let promise = this.storage.get('authToken');
      promise.then((authToken) => {
        if (authToken) this.authToken = authToken;
      }).catch(e => { console.warn('init error: apiService', e) });
      return promise;
    }
    
    async _apiCall(method: string, url: string, sendData: any, withLoading: boolean = true) {
      let loadingId = url + Date.now();
      await this.getAuthToken()
      return new Promise<any>((resolve, reject) => {
        
        if (withLoading) {
          this.presentLoading(loadingId);
        }
        
        let headers = new HttpHeaders();
        let opt: any = {};
        let fullUrl = '';
        
        opt.observe = 'response';
        if (url.indexOf('://') > 0 || url.indexOf('../') >= 0) {
        fullUrl = url;
      } else {
        fullUrl = environment.apiUrl + url;
        
        if (this.authToken) {
          opt.headers = { 'Authorization': 'Bearer ' + this.authToken };
        }
      }
      
      let httpRequest;
      
      if (method == 'get' || method == 'delete') {
        httpRequest = this.http[method](fullUrl, opt);
      } else {
        httpRequest = this.http[method](fullUrl, sendData, opt);
      }
      
      httpRequest.subscribe((response: any) => {
        resolve(this.responseHandler(response));
        if (withLoading) {
          this.dismissLoading(loadingId);
        }
      }, (err: any) => {
        if (withLoading) {
          this.dismissLoading(loadingId);
        }
        this.errorHandler(err);
        reject(err);
      });
    });
  }
  
  get(url: string, withLoading: boolean = true) {
    return this._apiCall('get', url, {}, withLoading);
  }
  
  post(url: string, sendData: any, withLoading: boolean = true) {
    return this._apiCall('post', url, sendData, withLoading);
  }
  
  put(url: string, sendData: any, withLoading: boolean = true) {
    return this._apiCall('put', url, sendData, withLoading);
  }
  
  delete(url: string, withLoading: boolean = true) {
    return this._apiCall('delete', url, {}, withLoading);
  }
  
  async getAuthToken() {
    if (!this.authToken) {
      this.authToken = await this.storage.get('authToken');
    }
    return this.authToken;
  } 
  
  isConnected() {
    return navigator.onLine;
  }
  
  async presentLoading(loadingId: any, msg: string = 'Por favor, aguarde...') {
    this.loading = true;
    this.loadingStack.push(loadingId);
  }
  
  dismissLoading(loadingId: any) {
    this.loadingStack.splice(this.loadingStack.indexOf(loadingId), 1);
    if (this.loadingStack.length === 0) this.loading = false;
  }
  
  responseHandler(response: any) {
    try {
      if (response.headers.get('token')) {
        this.authToken = response.headers.get('token');
        console.log('storage set auth token', this.authToken);
        this.storage.set('authToken', this.authToken);
        // localStorage.bearerToken = response.headers.get('Token');
      }
      // if (response.headers.get('Token')) {
      //   this.authToken = response.headers.get('Token');
      //   console.log('storage set auth token', this.authToken);
      //   this.storage.set('authToken', this.authToken);
      //   // localStorage.bearerToken = response.headers.get('Token');
      // }
      if (response.headers.get('Authenticated')) {
        this.storage.set('authenticated', response.headers.get('Authenticated'));
        // localStorage.setItem('authenticated', response.headers.get('Authenticated'));
      }
    } catch (e) { }
    switch (response.status) {
      case 200:
      return response.body;
      case 400:
      this.errorHandler(response.error);
      case 401:
      this.errorHandler(response.error);
      return { status: false };
      default:
      break;
    }
  }
  
  async errorHandler(error: any) {
    console.log(error);
    switch (error.status) {
      case 401:
      this._alert('session_expired');
      break;
      case 400:
      this._alert(error.error);
      break;
      case 422:
      this._alert('unprocessable_entity');
      break;
      default:
      this._alert();
      break;
    }
  }
  
  async _alert(msg: string = '', title: string = 'Ops') {
    if(msg === 'session_expired'){
      Swal.fire({ title: title, text: this.getErrorMessage(msg), icon: 'warning' }).then(async (result) => {
          await this.errorRedirect(msg)
          window.location.href = '/auth/login'
      })
    }
    if(msg !== 'subscription_not_found'){
      Swal.fire({ title: title, text: this.getErrorMessage(msg), icon: 'warning' }).then((result) => {
          this.errorRedirect(msg)
      })
    }
 
    // const alert = await this.alertCtrl.create({ message: msg, buttons: ['OK'] });
    // alert.present();
  }
  
  
  async errorRedirect(errMsg) {
    switch  (errMsg) {
      case "missing_tokens":
      window.location.href = '/'
      break;
      case "access_denied":
      // window.location.href = '/'
      break;
      case "session_expired":
      await this.logout()
      window.location.href = '/auth/login'
      break;
      default:
      break;
    }
  }
  getErrorMessage(errMsg) {
    let msg = '';
    switch (errMsg) {
      case "Invalid Credentials":
      msg = 'Invalid login and/or password!';
      break;
      case "existing_user":
      msg = 'User already exist';
      break;
      case "invalid_security_key":
      msg = 'invalid password';
      break;
      case "password_mismatch":
      msg = 'passwords do not match';
      break;
      case "invalid_email":
      msg = 'Invalid email';
      break;
      case "invalid_two_factor":
      msg = 'Invalid code';
      break;
      case "invalid_credentials":
      msg = 'Invalid email or password';
      break;
      case "mandatory_fields":
      msg = 'Required fields';
      break;
      case "register_error":
      msg = 'Error when registering, check if the data is correct';
      break;
      case "missing_tokens":
      msg = 'Ops, You have no more tokens';
      break;
      case "save_error":
      msg = 'Error saving, check if the data is correct';
      break;
      case "delete_error":
      msg = 'Error deleting, check for internet connection';
      break;
      case "share_error":
      msg = 'error sharing';
      break;
      case "default_error":
      msg = 'Error processing, try again';
      break;
      case "user_not_found":
      msg = 'User not found';
      break;
      case "user_exists_email":
      msg = 'Existing email';
      break;
      case "email_exists":
      msg = 'Existing email';
      break;
      case "already_registered_user":
      msg = 'User already exists';
      break;
      case "user_exists_document_number":
      msg = 'User already exists (CPF/CNPJ already used)';
      break;
      case "registry_code":
      msg = 'Invalid CPF/CNPJ';
      break;
      case "deal_closed":
      msg = 'This budget is already closed';
      break;
      case "item_already_in_cart":
      msg = 'This product is already in the cart';
      break;
      case "invalid_token":
      msg = 'Invalid Token';
      break;
      case "invalid_product":
      msg = 'Invalid product';
      break;
      case "product_code_exists":
      msg = 'Product code already registered';
      break;
      case "product_code_required":
      msg = 'Product code is mandatory';
      break;
      case "save_error":
      msg = 'Error saving';
      break;
      case "invalid_id":
      msg = 'invalid id';
      break;
      case "session_expired":
      msg = 'Your session has expired please login again';
      break;
      case "auth_required":
      msg = 'Login required';
      break;
      case "favorite_error":
      msg = 'error when bookmarking';
      break;
      case "card_expiration":
      msg = 'Invalid card validity';
      break;
      case "card_number":
      msg = 'Invalid card number';
      break;
      case "card_cvv":
      msg = 'invalid CVV';
      break;
      case "unique_voucher":
      msg = 'Code already used';
      break;
      case "invalid_voucher":
      msg = 'Invalid code';
      break;
      case "out_of_voucher":
      msg = 'Code ended :(';
      break;
      case "payment_company_code":
      msg = 'Invalid card data';
      break;
      case "address.state":
      msg = 'Invalid UF (must be the acronym)';
      break;
      case "address.country":
      msg = 'Invalid country acronym';
      break;
      case "mismatch_security_key":
      msg = 'passwords do not match';
      break;
      case "card_number_first_six":
      msg = 'Invalid card number';
      break;
      case "card_number_last_four":
      msg = 'Invalid card number';
      break;
      case "product_subscription_already_exists":
      msg = 'You already have this plan, you need to select another plan';
      break;
      case "unprocessable_entity":
      msg = 'We were not able to proccess your request at this time.';
      break;
      case "duplicated_registry":
      msg = 'Duplicated registry';
      break;
      case "account_not_confirmed":
      msg = 'Account not confirmed';
      break;
      case "access_denied":
      msg = 'Access denied';
      break;
      default:
      msg = 'An error occurred';
      break;
    }
    return msg;
  }
  isAuthenticated() {
    return new Promise((resolve, reject) => {
      this.storage.get('authenticated').then((authData) => {
        if (authData === 'true') {
          resolve(true);
        } else {
          resolve(false);
        }
      }).catch(() => {
        reject();
      });
    });
  }
  
  getCompleteAddress(cep: string) {
    
    let url = 'https://viacep.com.br/ws/' + cep.toString() + '/json';
    
    return new Promise((resolve, reject) => {
      
      if (cep.length === 8) {
        this.http.get(url)
        .subscribe((result: any) => {
          resolve(result);
        })
      }
    }).catch(err => console.log(err));
  }
  
  
  logout() {
    return new Promise((resolve) => {
      this.storage.delete('isAuthenticated');
      this.storage.delete('userData');
      this.storage.delete('authToken');
      this.storage.delete('profileView');
      this.authToken = '';
      resolve(true);
    });
    
  }
  
}
