import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NgSelectConfig } from '@ng-select/ng-select';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-artworks-list',
  templateUrl: './artworks-list.component.html',
  styleUrls: ['./artworks-list.component.scss']
})
export class ArtworksListComponent implements OnInit {
  
  @Input() endpoint: any;
  @Input() uid: any;
  @Input() items: any;
  @Input() title: boolean = true;
  @Input() filters: boolean = true;
  
  
  public data: any;
  public meta: any;
  public itemsBackup: any[] = [];
  public link: any = "artworks";
  public linkCreateArtwork: any;
  public searchFilter: any = "";
  public activeStatusFilter: any = "all";
  public order: any = "-id";
  searchChanged: Subject<string> = new Subject<string>();
  
  constructor(
    public helper: HelperService,
    public api: ApiService,
    public auth: AuthService,
    private config: NgSelectConfig,
    ) {
      this.searchChanged.pipe(debounceTime(300), distinctUntilChanged()).subscribe(async (model) => { 
        this.searchFilter = model
        if(this.uid){
          await this.api.get(this.endpoint+this.uid+'?page='+1+'&status='+this.activeStatusFilter+'&search='+this.searchFilter+'&order='+this.order).then(async (response) => {
            this.items = response
            this.data = response.data
            this.meta = response.meta
            this.itemsBackup = this.data
          })
        }else{
          await this.api.get(this.endpoint+'?page='+1+'&status='+this.activeStatusFilter+'&search='+this.searchFilter+'&order='+this.order).then(async (response) => {
            this.items = response
            this.data = response.data
            this.meta = response.meta
            this.itemsBackup = this.data
          })
        }
      });
      
      this.config.notFoundText = 'Custom not found';
      this.config.appendTo = 'body';
      this.config.bindValue = 'value';
    }
    
    async ngOnInit() {
      if(this.items){
        this.itemsBackup = this.items.data
        this.data = this.items.data
        this.meta = this.items.meta
      }
      await this.setLink()
      if(this.endpoint && !this.data){
        if(this.uid){
          this.api.get(this.endpoint+this.uid).then((response) => {
            this.items = response
            this.itemsBackup = response.data
            this.data = response.data
            this.meta = response.meta
          })
        }else{
          this.api.get(this.endpoint).then((response) => {
            this.items = response
            this.itemsBackup = response.data
            this.data = response.data
            this.meta = response.meta
          })
        }
      }
    }
    
    async loadMore(){
      if(this.uid){
        await this.api.get(this.endpoint+this.uid+'?page='+(this.meta.current_page+1)+'&status='+this.activeStatusFilter+'&search='+this.searchFilter+'&order='+this.order).then(async (response) => {
          for await (let item of response.data){
            this.data.push(item)
          }
          this.meta = response.meta
          this.itemsBackup = this.data
        })
      }else{
        await this.api.get(this.endpoint+'?page='+(this.meta.current_page+1)+'&status='+this.activeStatusFilter+'&search='+this.searchFilter+'&order='+this.order).then(async (response) => {
          for await (let item of response.data){
            this.data.push(item)
          }
          this.meta = response.meta
          this.itemsBackup = this.data
        })
      }
    }
    
    setLink(){
      switch(this.endpoint){
        case 'institutions/artworks' :
        this.link = 'artworks'
        this.linkCreateArtwork = '/dashboard-institution/artworks-create'
        break
        case 'creators/artworks' :
        this.link = 'artworks'
        this.linkCreateArtwork = '/dashboard-creator/artworks-create'
        break
        case 'artworks/getbyUserId/' :
        this.link = 'artworks'
        this.linkCreateArtwork = '/dashboard-creator/artworks-create'
        break
        case 'collector/artworks' :
        this.link = 'versions'
        this.linkCreateArtwork = '/subscriber'
        break
      }
    }


    // this.api.get('institutions/'+this.institution.id+'/artworks/').then((response) => {
    //   this.artworks = response
    // })
    
    async changeStatusFilter(stauts){
      this.activeStatusFilter = stauts
      this.items.data = this.itemsBackup
      if(this.uid){
        await this.api.get(this.endpoint+this.uid+'?page='+1+'&status='+this.activeStatusFilter+'&search='+this.searchFilter+'&order='+this.order).then(async (response) => {
          this.items = response
          this.data = response.data
          this.meta = response.meta
          this.itemsBackup = this.data
        })
      }else{
        await this.api.get(this.endpoint+'?page='+1+'&status='+this.activeStatusFilter+'&search='+this.searchFilter+'&order='+this.order).then(async (response) => {
          this.items = response
          this.data = response.data
          this.meta = response.meta
          this.itemsBackup = this.data
        })
      }
    }
    
    public async changeOrder(order){
      console.log(order);
      this.order = order;
      if(this.uid){
        await this.api.get(this.endpoint+this.uid+'?page='+1+'&status='+this.activeStatusFilter+'&search='+this.searchFilter+'&order='+this.order).then(async (response) => {
          this.items = response
          this.data = response.data
          this.meta = response.meta
          this.itemsBackup = this.data
        })
      }else{
        await this.api.get(this.endpoint+'?page='+1+'&status='+this.activeStatusFilter+'&search='+this.searchFilter+'&order='+this.order).then(async (response) => {
          this.items = response
          this.data = response.data
          this.meta = response.meta
          this.itemsBackup = this.data
        })
      }
    }
    
    async searchItem(value: string) {
      this.searchChanged.next(value);
    }
    
    optionsOrderBy = [
      { id: '-id', name: 'More Relevant' },
      { id: '-price', name: 'Higher price' },
      { id: 'price', name: 'Lowest price' },
      { id: 'year', name: 'Newest year' },
      { id: '-year', name: 'Oldest year' },
    ];
  }