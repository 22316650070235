<h1 class="text-secondary font-weight-bold mb-0" style="font-size: 45px !important;">{{ artwork.title }}</h1>
<p class="font-weight-light"><a href="javascript:void(0)" [routerLink]="['/artist-detail/'+artwork.artist.slug]">By {{ artwork.artist.full_name }}</a></p>
<div>
    <hr class="m-0">
    <div class="d-flex justify-content-between align-items-center">
        <p class="text-secondary font-weight-bold py-3 mb-0">Size</p>
        <p class="text-secondary py-3 mb-0">{{ artwork.width }} x {{ artwork.height }} - {{ artwork.unit }}</p>
    </div>
    <hr class="m-0">
    <div class="d-flex justify-content-between align-items-center">
        <p class="text-secondary py-3 mb-0 font-weight-bold">Medium</p>
        <p class="text-secondary py-3 mb-0">{{ artwork.medium }}</p>
    </div>
    <hr class="m-0">
    <div class="d-flex justify-content-between align-items-center">
        <p class="text-secondary py-3 mb-0 font-weight-bold">Style</p>
        <p class="text-secondary py-3 mb-0">{{ artwork.style.title }}</p>
    </div>
    <hr class="m-0">
    <div class="d-flex justify-content-between align-items-center">
        <p class="text-secondary py-3 mb-0 font-weight-bold">Type</p>
        <p class="text-secondary py-3 mb-0">{{ artwork.digital ? 'Digital' : 'Physical'}}</p>
    </div>
    <hr class="m-0">
    <div class="d-flex justify-content-between align-items-center">
        <p class="text-secondary py-3 mb-0 font-weight-bold">Production Date</p>
        <p class="text-secondary py-3 mb-0">{{ artwork.year }}</p>
    </div>
    <hr class="m-0">
    <div class="d-flex justify-content-between align-items-center">
        <p class="text-secondary font-weight-bold py-3 mb-0">Edition</p>
        <p *ngIf="artwork.version != 'sold_out'"  class="text-secondary py-3 mb-0">{{ artwork.version.version }}/{{ artwork.edition }}</p>
        <p *ngIf="artwork.version == 'sold_out'"  class="text-secondary py-3 mb-0">{{ artwork.edition }}/{{ artwork.edition }}</p>
    </div>
    <hr *ngIf="artwork.version != 'sold_out'" class="m-0">
    <div *ngIf="artwork.version != 'sold_out'"  class="d-flex justify-content-between align-items-center">
        <p class="text-secondary py-3 mb-0 font-weight-bold">Price</p>
        <h1 class="text-primary">{{ helper.amountCurrencyFormat(artwork.version.price) }}</h1>
    </div>
    <hr class="m-0">
    <!-- <p class="font-weight-light mt-5 mb-4">Legal disclaimer if needed. Lorem ipsum dolor sit amet dolor sit amet dolor sit amet dolor sit amet.</p> -->

    <!-- <p class="mt-5">Legal disclaimer if needed. Lerem ipsum dolor sit amet <br> dolor sit amet dolor sit amet dolor sit amet</p> -->
<button type="button" *ngIf="artwork.version != 'sold_out'" (click)="buy('?tradeQr='+artwork.version.fake_token)" class="btn btn-lg btn-primary w-100"> Buy and add to my vault</button>
    <h3 *ngIf="artwork.version == 'sold_out'" class="w-100"> Sold out</h3>
</div>