import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { ModalAdressConfirmedComponent } from '../modals/modal-adress-confirmed/modal-adress-confirmed.component';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  formAuthenticateRegister(formAuthenticateRegister: any, redirect: boolean): any {
    throw new Error('Method not implemented.');
  }
  public isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public subscriptionActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public institutionActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public userData: any = {};
  public profileView: string = 'collector';
  
  public formTwoFactor: FormGroup;
  public formRegister: FormGroup;
  public formLogin: FormGroup;
  public formForgotPassword: FormGroup;
  public formRecoveryPassword: FormGroup;
  
  
  constructor(
    public storageService: StorageService,
    // public modalService: NgbModal,
    public apiService: ApiService,
    public formBuilder: FormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    ) {
      this.createFormRegister();
      this.createFormLogin();
      this.createFormTwoFactor();
      this.createFormForgotPassword();
      this.createFormRecoveryPassword();
    }
    
    init() {
      this.storageService.get('profileView').then((response: string) => {
        if(response !== null && typeof response != 'undefined'){
          this.profileView = response
        }
      });
      this.storageService.get('isAuthenticated').then((isAuthenticated: boolean) => {
        if (isAuthenticated) {
          this.isAuthenticated.next(true);
          this.storageService.get('userData').then((userData: any) => {
            this.userData = JSON.parse(userData);
            this.hasSubscriptionActive();
          });
        } else {
          this.isAuthenticated.next(false);
        }
      });
    }
    
    isProfile(profile: string) {
      return profile === this.profileView;
    }
    
    checkPermissions(permission: string) {
      switch (permission) {
        case 'switch-account':
        if (this.isAuthenticated.getValue() === true /* && this.subscriptionActive.getValue() === true */) return true;
        break;
        case 'signed-in':
        if (this.isAuthenticated.getValue() === true) return true;
        break;
      }
      return false;
    }
    
    switchProfileView(profile: string, redirect = true) {
      this.profileView = profile;
      this.storageService.set('profileView', profile);
      switch (profile) {
        case 'collector':
        if(redirect)
        this.router.navigateByUrl('/dashboard');
        break;
        case 'creator':
        if(redirect)
        this.router.navigateByUrl('/dashboard-creator/overview');
        break;
        case 'institution':
        if(redirect)
        this.router.navigateByUrl('/dashboard-institution/overview');
        break;
      }
    }
    
    hasSubscriptionActive() {
      if ((this.userData.subscription && this.userData.subscription != null && this.userData.subscription.plan.institution == false) || (this.userData.invites && this.userData.invites.length > 0)) {
        this.institutionActive.next(false);
        this.subscriptionActive.next(true);
      } else if (this.userData.subscription && this.userData.subscription != null && this.userData.subscription.plan.institution == true) {
        this.institutionActive.next(true);
        this.subscriptionActive.next(false);
      } else {
        this.subscriptionActive.next(false);
      }
    }
    
    currentAuthenticatedUser(endpoint = 'users/me') {
      let promise = this.apiService.get(endpoint);
      promise.then((response) => {
        this.storageService.set('userData', JSON.stringify(response));
        this.userData = response;
      });
      return promise;
    };
    
    updateUserAddress(formData, endpoint = 'user/address') {
      // TO DO
    }
    
    updateSubscriptionData() {
      return this.apiService.get('user/subscription/'+this.userData.id).then((response) => {
        this.userData.subscription = response
        this.storageService.set('userData', JSON.stringify(this.userData));
        this.hasSubscriptionActive()
        
        if(!this.subscriptionActive.getValue() && this.profileView == 'creator'){
          this.profileView = 'collector';
          this.storageService.set('profileView', 'collector');
          // window.location.href = '/subscribe'
          return '/subscribe'
          
        }
        if(!this.institutionActive.getValue() && this.profileView == 'institution'){
          this.profileView = 'collector';
          this.storageService.set('profileView', 'collector');
          return '/subscribe'
          // window.location.href = '/subscribe'
        }
        return 'active'
      }).catch((err) => {
        if(typeof this.userData.invites != 'undefined' && this.userData.invites.length > 0 && this.userData.subscription == null){
          this.hasSubscriptionActive()
          return 'active'
        }else{
          this.profileView = 'collector';
          this.storageService.set('profileView', 'collector');
          delete this.userData.subscription
          this.storageService.set('userData', JSON.stringify(this.userData));
          this.hasSubscriptionActive()
          return '/subscribe'
          // window.location.href = '/subscribe'
        }
      })
    }
    
    updateUser(formData, endpoint = 'user') {
      let payload = { data: formData };
      let promise = this.apiService.put(endpoint, payload);
      promise.then((response) => {
        this.storageService.set('userData', response.user);
        this.userData = response.user;
        this.hasSubscriptionActive();
      })
      return promise;
    }
    
    // async forgotPassword(formData, endpoint = 'auth/user/forgotPassword', processPromise = true) {
    //   let payload = { data: formData };
    //   let promise = this.apiService.post(endpoint, payload);
    //   if (processPromise == true) {
    //     promise.then(async () => {
    //       alert('Foi enviado um e-mail com link para recuperação para: ' + formData.email);
    //     }).catch((e) => {
    //       // console.log((e);
    //     });
    //   }
    //   return promise;
    // }
    
    // newPassword(formData, endpoint = 'auth/user/recoveryPassword', processPromise = true) {
    //   let payload = { data: formData };
    //   let promise = this.apiService.post(endpoint, payload);
    //   if (processPromise == true) {
    //     promise.then(async () => {
    //       alert('Senha atualizada com sucesso!');
    //     }).catch((e) => {
    //       // console.log((e);
    //     });
    //   }
    //   return promise;
    // }
    
    // changePassword(formData, endpoint = 'user/change-password', processPromise = true) {
    //   let payload = { data: formData };
    //   let promise = this.apiService.post(endpoint, payload);
    //   if (processPromise == true) {
    //     promise.then(async () => {
    //       alert('Senha alterada com sucesso!');
    //     }).catch((e) => {
    //       // console.log((e);
    //     });
    //   }
    //   return promise;
    // }
    
    // register(formData, endpoint = 'user/register') {
    //   let payload = { data: formData };
    //   let promise = this.apiService.post(endpoint, payload);
    //   promise.then((response) => {
    //     this.storageService.set('isAuthenticated', true);
    //     this.storageService.set('userData', JSON.stringify(response.user));
    //     this.isAuthenticated.next(true);
    //     this.userData = response.user;
    //     this.hasSubscriptionActive();
    //   })
    //   return promise;
    // }
    
    login(payload, endpoint = 'auth/twoFactor', redirect = true) {
      let promise = this.apiService.post(endpoint, payload);
      promise.then((response) => {
        this.storageService.set('isAuthenticated', true);
        this.storageService.set('userData', JSON.stringify(response.user));
        this.isAuthenticated.next(true);
        this.userData = response.user;
        this.hasSubscriptionActive();
        this.switchProfileView('collector', redirect)
      })
      return promise;
    }
    
    logout() {
      return new Promise((resolve) => {
        this.storageService.delete('isAuthenticated');
        this.storageService.delete('userData');
        this.storageService.delete('authToken');
        this.userData = {};
        this.apiService.authToken = '';
        this.isAuthenticated.next(false);
        this.subscriptionActive.next(false);
        window.location.href = '/auth/login'
        resolve(true);
      });
      
    }
    
    
    
    createFormTwoFactor() {
      this.formTwoFactor = this.formBuilder.group({
        input1: ['', Validators.required],
        input2: ['', Validators.required],
        input3: ['', Validators.required],
        input4: ['', Validators.required],
        input5: ['', Validators.required],
        input6: ['', Validators.required]
      })
    }
    createFormLogin() {
      this.formLogin = this.formBuilder.group({
        email: ['', Validators.required],
        password: ['', Validators.required]
      })
    }
    createFormRegister() {
      this.formRegister = this.formBuilder.group({
        first_name: [this.userData ? this.userData.first_name : null, Validators.required],
        last_name: [this.userData ? this.userData.last_name : null, Validators.required],
        phone_number: [this.userData ? this.userData.phone_number: null, [Validators.required, Validators.minLength(3)]],
        email: [this.userData ? this.userData.email : null, [Validators.required, Validators.email]],
        password: [null, [Validators.required, Validators.minLength(3)]],
        password_confirmation: [null, [Validators.required, Validators.minLength(3)]]
      })
    }
    createFormForgotPassword() {
      this.formForgotPassword = this.formBuilder.group({
        email: ['', Validators.required]
      })
    }
    createFormRecoveryPassword() {
      this.formRecoveryPassword = this.formBuilder.group({
        password: ['', Validators.required],
        password_confirmation: ['', Validators.required]
      })
    }
    
    
    async submitRegisterForm({ valid, value }: { valid: boolean; value: any }, navigate: boolean = true) {
      if (valid) {
        var payload = {
          full_name: value.first_name + ' ' + value.last_name,
          email: value.email,
          phone_number: value.phone_number.dialCode.replace('+', '') + value.phone_number.number,
          password: value.password,
          password_confirmation: value.password_confirmation,
        }
        
        let call = this.apiService.post('register', payload)
        call.then((response) => {
          this.route.queryParams.subscribe(params => {
            if (params['hash_token']) {
              if (navigate) {
                this.router.navigate(['/auth/authenticate-register'], {queryParams: {hash_token: params['hash_token']}});
              }
            }else
            if (params['invite']) {
              if (navigate) {
                this.router.navigate(['/auth/authenticate-register'], {queryParams: {invite: params['invite']}});
              }
            }else{
              if (navigate) this.router.navigateByUrl('/auth/authenticate-register');
            }
          });
          
        })
        return call;
      }
    }
    
    
    async recoveryPassword({ valid, value }: { valid: boolean; value: any }, navigate: boolean = true) {
      if (valid) {
        let token;
        await this.route.queryParams.subscribe(params => {
          token = params.token;
        });
        
        let payload = {
          token: token,
          password: value.password,
          password_confirmation: value.password_confirmation,
        }
        
        if (this.formRecoveryPassword.controls.password.value == this.formRecoveryPassword.controls.password_confirmation.value) {
          let call = this.apiService.put('auth/recoveryPassword', payload)
          call.then((response) => {
            if (navigate) this.router.navigateByUrl('/auth/login');
          })
          return call;
        } else {
          // console.log(('ERROR');
        }
      }
    }
    
    async forgotPassword({ valid, value }: { valid: boolean; value: any }, navigate: boolean = true) {
      if (valid) {
        let payload = {
          email: value.email,
        }
        
        let call = this.apiService.post('auth/forgotPassword', payload)
        call.then((response) => {
          // // console.log((response)
          if (navigate) this.router.navigateByUrl('/auth/sendRecoveryPassword');
        })
        return call;
      }
    }
    
    async twoFactor({ valid, value }: { valid: boolean; value: any }, navigate: boolean = true) {
      if (valid) {
        let twoFactor: any = value.input1 + '' + value.input2 + '' + value.input3 + '' + value.input4 + '' + value.input5 + '' + value.input6
        // twoFactor = parseInt(twoFactor);
        let payload = {
          email: this.formLogin.controls.email.value,
          password: this.formLogin.controls.password.value,
          twoFactor: twoFactor
        }
        let call = this.login(payload, 'auth/twoFactor', false)
        call.then((response) => {
          this.route.queryParams.subscribe(params => {
            
            this.updateSubscriptionData().then(res => {
              if (params['hash_token']) {
                if (navigate) {
                  
                  if(this.institutionActive.getValue() == true){
                    this.switchProfileView('institution', false)
                    document.location.href  = '/dashboard-institution/overview'
                  }else if(this.subscriptionActive.getValue() == true){
                    this.switchProfileView('creator', false)
                    document.location.href  = '/dashboard-creator/overview'
                  }else{
                    this.router.navigate(['/dashboard/artworks'], {queryParams: {invite: params['hash_token']}});
                  }
                  
                }
              }else
              if (params['invite']) {
                if (navigate) {
                  if(this.institutionActive.getValue() == true){
                    this.switchProfileView('institution', false)
                    document.location.href  = '/dashboard-institution/overview'
                  }else if(this.subscriptionActive.getValue() == true){
                    this.switchProfileView('creator', false)
                    document.location.href  = '/dashboard-creator/overview'
                  }else{
                    this.router.navigate(['/dashboard/artworks'], {queryParams: {invite: params['invite']}});
                  }
                }
              }else{
                if (navigate) {
                  if(this.institutionActive.getValue() == true){
                    // console.log(('sou Institution')
                    this.switchProfileView('institution', false)
                    document.location.href  = '/dashboard-institution/overview'
                  }else if(this.subscriptionActive.getValue() == true){
                    // console.log(('sou Creator')
                    this.switchProfileView('creator', false)
                    document.location.href  = '/dashboard-creator/overview'
                  }else{
                    // console.log(('sou Collector')
                    this.router.navigateByUrl('/dashboard/artworks');
                  }
                }
              }
            })
            
          });
        })
        return call;
      }
    }
    
    async submitLoginForm({ valid, value }: { valid: boolean; value: any }, navigate: boolean = true) {
      if (valid) {
        let payload = {
          email: value.email,
          password: value.password,
        }
        let email = value.email
        let password = value.password
        
        let call = this.apiService.post('auth/login', payload);
        call.then((response) => {
          if (navigate) this.router.navigateByUrl('/auth/authenticate-login');
        }).catch((err) => {
          if (err.error == "account_not_confirmed") {
            if (navigate) this.router.navigateByUrl('/auth/authenticate-register');
          }
        })
        return call;
      }
    }
    
    async confirmEmail({ valid, value }: { valid: boolean; value: any }, navigate: boolean = true) {
      let twoFactor: any = value.input1 + '' + value.input2 + '' + value.input3 + '' + value.input4 + '' + value.input5 + '' + value.input6
      let payload = {
        email: this.formRegister.controls.email.value ? this.formRegister.controls.email.value : this.formLogin.controls.email.value,
        token: twoFactor
      };
      let call = this.login(payload, 'register/emailConfirm')
      call.then((response) => {
        this.route.queryParams.subscribe(params => {
          if (params['hash_token']) {
            if (navigate) {
              this.router.navigate(['/dashboard/artworks'], {queryParams: {trade: params['hash_token']}});
            }
          }else if (params['invite']) {
            if (navigate) {
              this.router.navigate(['/dashboard/artworks'], {queryParams: {invite: params['invite']}});
            }
          }else{
            if (navigate) this.router.navigateByUrl('/dashboard/artworks');
          }
        });
        // const modalref = this.modalService.open(ModalAdressConfirmedComponent);
        // modalref.componentInstance.trade = this.hashToken;
      })
      return call
    }
    
    
    
  }
  