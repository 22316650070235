<div class="row my-3" *ngIf="title">
    <div class="col-lg-6">
        <h1 class="font-weight-bold text-secondary">Your Folders</h1>
    </div>
    <div class="col-lg-6">
        <div class="text-sm-right">
            <button type="button" (click)="openCreateFolders()" class="btn btn-lg btn-primary btnDash font-weight-regular cursor-pointer">Create new folders</button>
        </div>
    </div>
</div>
<div class="border-light pb-0">
    <app-artworks-track [folders]="folders"></app-artworks-track>
    <div *ngIf="folders?.length == 0" class="text-center my-5 py-5">
        <h1>Create your first Folder</h1>
        <p>Use folders to organize or save artworks by client, room, etc. All folders are private unless you share them.</p>
        <button type="button" class="btn btn-primary" (click)="openCreateFolders()">Create Folder</button>
    </div>
</div>