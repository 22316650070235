import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalAdressConfirmedComponent } from 'src/app/modals/modal-adress-confirmed/modal-adress-confirmed.component';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  public viewMode: any;
  public formData: any = {};
  public email: any;
  public password: any;
  public token: any;
  public password_confirmation: any;

  public hashToken: any;



  constructor(
    public router: Router,
    public api: ApiService,
    public auth: AuthService,
    public route: ActivatedRoute

  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.ngOnInit();
        this.route.queryParams.subscribe(params => {
          if (params['email']) {
            this.api.get('user/findPreCad/' + params['email']).then((response) => {
              this.auth.userData = response
              this.auth.userData.first_name = this.auth.userData.full_name.split(' ')[0]
              this.auth.userData.last_name = this.auth.userData.full_name.split(' ')[1]
              this.auth.createFormRegister();
            })
          }
          var isAuth = this.auth.isAuthenticated.getValue()
          if (params['hash_token']) {
            if (isAuth) {
              window.location.href = '/dashboard?trade=' + params['hash_token']
            }
            this.hashToken = params['hash_token']
          }else if (params['invite']) {
            if (isAuth) {
              window.location.href = '/dashboard?invite=' + params['invite']
            }
          }else {
            if (isAuth) {
              window.location.href = '/dashboard'
            }
          }
        });
      }
    });
  }

  ngOnInit(): void {
    this.viewMode = this.route.snapshot.params.viewMode
  }



  async goToLogin() {
    this.viewMode = 'login'
  }

  async goToHome() {
    return this.router.navigateByUrl("/home")
  }

 

  async goToForgotPassword() {
    this.viewMode = 'forgotPassword'
  }

  async goToRegister() {
    this.viewMode = 'register'
  }

  goBack(){
    window.history.back();
  }

}