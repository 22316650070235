<div>
    <div class="modal-header justify-content-center">
        <h2 class="text-secondary font-weight-bold mt-2">Add artwork to folder</h2>
        <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0 p-1">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
    <div class="container modal-body">
        <div class="row px-5 mx-2">
            <p class="text-center font-weight-light text-grey-light invited-p">Use lists to organize or save artworks by client, room, etc. All lists are private unless you share them.</p>
        </div>
        <div class="row d-flex justify-content-between mt-3 px-4">
            <div class="col-lg-5">
                <app-preview-artwork [preview]="artwork.img" [img]="artwork.img" [styleImage]="'border-radius: 9px; width: 203px;height: 236px;'"></app-preview-artwork>
                <div class="">
                    <p class="text-secondary font-weight-bold mb-0">{{ artwork.title }}</p>
                    <p class="text-grey-light font-weight-light mb-0">{{ artwork.artist.full_name }}</p>
                </div>
            </div>
            <div class="col-lg-6 pr-0">
                <div ngbDropdown class="d-inline-block w-100">
                    <p class="text-grey-link font-weight-light mb-2">Add to your lists</p>
                    <ng-select [(ngModel)]="selectedFolder" [placeholder]="'Add to your lists'" class="custom">
                        <ng-option *ngFor="let folder of folders" [value]="folder.id">{{folder.title}}</ng-option>
                    </ng-select>
                </div>
                <div class="mt-4 px-0">
                    <p class="text-grey-link font-weight-light mb-1">Create a new list</p>
                    <input [(ngModel)]="newFolder" class="bg-light border-0 col py-4" type="text" placeholder="List name" id="senha" required>
                    <button type="button" (click)="createFolders()" class="d-flex flex-column justify-content-center cursor-pointer"><img src="/assets/icons/button-add.png"></button>
                </div>
            </div>
        </div>
    </div>
    <div class="container mt-4">
        <div class="row modal-footer d-flex justify-content-center">
            <div class="col-lg-6">
                <button (click)="createAddtoFolder(selectedFolder, artwork.id)" type="submit" class="w-100 btn btn-lg btn-primary font-weight-regular btn-invite">Add artwork</button>
            </div>
        </div>
    </div>
</div>