import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Observer } from 'rxjs/internal/types';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { ImageService } from 'src/app/services/image.service';

@Component({
  selector: 'app-versionSpecs',
  templateUrl: './versionSpecs.component.html',
  styleUrls: ['./versionSpecs.component.scss']
})
export class VersionSpecsComponent implements OnInit {
  
  @Input() artwork: any 
  @Input() version: any
  public img: any = '';
  public style: any = '';
  data: string;
  
  constructor(
    private api: ApiService,
    private auth: AuthService,
    ) { }
    
    base64Image: any;
    
    ngOnInit(): void {
      if (this.artwork && this.version) {
        this.artwork.version = this.version
        
        console.log(this.artwork)
        console.log(this.version)
      }
      
    }
    
    
    toDataURL(url) {
      return fetch(url)
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        return URL.createObjectURL(blob);
      });
    }
    
    async download() {
      this.api.loading = true
      const a = document.createElement("a");
      a.href = await this.toDataURL(this.artwork.img);
      a.download = this.artwork.title.toLowerCase().replace(/\s/g, '-');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      this.api.loading = false
    }
    
  }