<main *ngIf="showPage" id="dashboard-creator">
    <section class="px-sm-3 overview">
        <div class="container-fluid text-welcome">
            <div class="row my-sm-4 mx-4 px-2">
                <div class="col-lg-12 p-1">
                    <p class="text-grey font-weight-light mb-0">Your Vault</p>
                    <h1 class="text-primary font-weight-bold display-4 mb-4">Welcome, {{ auth.userData.full_name }}</h1>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row mx-sm-4">
                <div class="col-lg-12">
                    <div class="card mb-5">
                        <div class="card-header px-3 pb-0">
                            <app-dash-create [disabledChart]="disabledMode"></app-dash-create>
                        </div>
                        <div class="card-body" *ngIf="viewMode === 'overview'">
                            <div class="row">
                                <div class="col-md-6 pt-4" >
                                    <app-info-dashboard [mode]="'creator'"></app-info-dashboard>
                                </div>
                                <div class="col-md-6 pt-4" >
                                    <app-latest-artwork-created [artworkLastCreated]="artworkLastCreated"></app-latest-artwork-created>
                                </div>
                            </div>
                            <!-- <hr class="my-4"> -->
                            <!-- <div class="row justify-content-between px-3">
                                <div class="col-lg-12 mb-0">
                                    <app-last-trades></app-last-trades>
                                </div>
                            </div> -->
                            <div class="row mt-5">
                                <div class="col-12">
                                    <h1 class="text-secondary font-weight-bold ">Recent Activity</h1>
                                </div>
                            </div>
                            <div class="">
                                <div class="row mt-2">
                                    <div class="col-12 d-sm-flex border-bottom" id="titles-card-dash">
                                        <a class="text-decoration-none font-weight-light text-grey mr-4 cursor-pointer h2 cursor-pointer" [class.active]="overviewMode === 'artworks'" (click)="setOverviewMode('artworks')">Artworks</a>
                                        <a class="text-decoration-none font-weight-light text-grey cursor-pointer h2 cursor-pointer" [class.active]="overviewMode === 'folders'" (click)="setOverviewMode('folders')">Folders</a>
                                    </div>
                                </div>
                                <div class="d-flex flex-wrap" *ngIf="overviewMode === 'artworks'">
                                    <app-artworks-list *ngIf="artworks" [items]="artworks" [title]="false" [filters]="false" [endpoint]="'creators/artworks'"></app-artworks-list>
                                    <!-- <app-create-dropdown-options-artworks [artwork_id]="artwork.id" class="dropdown-to-artworks"></app-create-dropdown-options-artworks> -->
                                </div>
                                <div class="row d-flex justify-content-center" *ngIf="overviewMode === 'folders'">
                                    <app-folders-box [title]="false"></app-folders-box>
                                </div>
                            </div>
                        </div>
                        
                        <div class="card-body" *ngIf="viewMode === 'artworks'">
                            <app-artworks-list [endpoint]="'creators/artworks'"></app-artworks-list>
                        </div>
                        
                        <div class="card-body" *ngIf="viewMode === 'folders'">
                            <app-folders-box></app-folders-box>
                        </div>
                        
                        <div class="card-body pt-0 px-0" *ngIf="viewMode === 'artworks-create'">
                            <app-create-artworks [mode]="'creator'"></app-create-artworks>
                        </div>
                        
                        <div class="card-body pt-3" *ngIf="viewMode === 'trades'">
                            <app-trade-box></app-trade-box>
                        </div>
                        
                        <div *ngIf="viewMode === 'profile'" class="card-body px-0">
                            <app-profile></app-profile>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>