import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalTradeDigitalComponent } from 'src/app/modals/modal-trade-digital/modal-trade-digital.component';
import { ModalTransactionComponent } from 'src/app/modals/modal-transaction/modal-transaction.component';
import { YourSmartContractComponent } from 'src/app/modals/your-smart-contract/your-smart-contract.component';
import * as Highcharts from 'highcharts';

import { HighChartsService } from 'src/app/services/highcharts.service';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';


@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {
  public isActive:boolean = true;
  public id: number;
  public status: any = {
    1: "Request",
    2: "Accepted",
    3: "Recused",
    4: "Payment Pending",
    5: "Payed",
    6: "Cancelled",
    7: "Purchased",
  };
  public version: any;
  public histories: any;
  public viewMode: any;
  Highcharts: typeof Highcharts = Highcharts;
  
  chartOptions: Highcharts.Options = {};
  
  constructor(
    public router: Router,
    public modalService: NgbModal,
    public route: ActivatedRoute,
    public hcSercive: HighChartsService,
    public helper: HelperService,
    public api: ApiService
    ) {
      router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          this.viewMode = this.route.snapshot.params.viewMode ? this.route.snapshot.params.viewMode : 'initial'
        }
      });
    }
    
    ngOnInit(): void {
      this.id = this.route.snapshot.params.id
      this.api.get('versions/' + this.id).then((response) => {
        this.version = response
        
        let dateCreated =  new Date(response.created_at)
        
        let month = (dateCreated.getMonth()+1) < 9 ? '0'+(dateCreated.getMonth()+1) : (dateCreated.getMonth()+1);
        let day = (dateCreated.getDate()) < 9 ? '0'+(dateCreated.getDate()) : (dateCreated.getDate());
        this.version.chartData = [
          { x: month+'/'+day+'/'+ dateCreated.getFullYear(), y: this.version.artwork.original_price/100 },
        ]
        
        
        this.api.get('transfers/' + this.version.id + '/history').then(async (response) => {
          this.histories = response
          for await (let history of response){
            if(history.status = 5){
              let dateCreated = new Date(history.paid_at)
              const result = this.version.chartData.find(data => data.x === (dateCreated.getMonth()+1)+'/'+ dateCreated.getFullYear());
              if(result){
                result.y += history.amount / 100
              }else{
                let month = (dateCreated.getMonth()+1) < 9 ? '0'+(dateCreated.getMonth()+1) : (dateCreated.getMonth()+1);
                let day = (dateCreated.getDate()) < 9 ? '0'+(dateCreated.getDate()) : (dateCreated.getDate());
                this.version.chartData.push({x: month+'/'+day+'/'+ dateCreated.getFullYear()+' #'+history.id,y: history.amount / 100})
              }
              this.chartOptions = this.hcSercive.populateSplineChart(this.version.chartData);
            }
          }
        })
        
        
      })
      
    }
    
    public textSubject(history: any) {
      if (history.status === 1) {
        return history.seller.full_name
      } else {
        return history.purchaser.full_name
      }
    }
    
    public textStatus(status: any) {
      return this.status[status]
    }
    
    openTransaction() {
      const modalRef = this.modalService.open(ModalTradeDigitalComponent,
        {
          windowClass: 'myCustomModalTrade'
        });
      modalRef.componentInstance.version = this.version;
    }
    
    openTransactionTwo(instance) {
      const modalRef = this.modalService.open(ModalTransactionComponent);
      modalRef.componentInstance.version = instance;
    }
    
    openYourSmartContract(instance) {
      const modalRef = this.modalService.open(YourSmartContractComponent, { windowClass: "contract-modal" });
      modalRef.componentInstance.version = this.version;
      
    }
    
  }
  