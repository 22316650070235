<div *ngIf="artwork">
    <app-preview-artwork [img]="artwork?.img" [preview]="artwork?.img" [styleImage]="'width: 100%; border-radius: 9px;'" ></app-preview-artwork>
    <a *ngIf="auth.userData.id == artwork?.institution_id || auth.userData.id == artwork?.artist_id || auth.userData.id == version?.owner_id" href="javascript:void(0)" (click)="download()" title="{{artwork?.title}}">Download My NFT</a>
    <div class="row">
        <div class="col-lg-12 mt-4">
            <hr class="m-0">
            <div class="row mt-2 p-1">
                <div class="col-lg-6">
                    <p class="font-weight-bold text-secondary">Size</p>
                </div>
                <div class="offset-lg-1 col-lg-5">
                    <p class="text-grey">{{ artwork?.width }} x {{ artwork?.height }} ({{ artwork?.unit }})</p>
                </div>
            </div>
            
            <hr class="m-0">
            <div class="row mt-2 p-1">
                <div class="col-lg-6">
                    <p class="font-weight-bold text-secondary">Medium</p>
                </div>
                <div class="offset-lg-1 col-lg-5">
                    <p class="text-grey">{{ artwork?.medium }}</p>
                </div>
            </div>
            <hr class="m-0">
            <div class="row mt-2 p-1">
                <div class="col-lg-6">
                    <p class="font-weight-bold text-secondary">Style</p>
                </div>
                <div class="offset-lg-1 col-lg-5">
                    <p class="text-grey">{{ artwork?.style.title }}</p>
                </div>
            </div>
            <hr class="m-0">
            <div class="row mt-2 p-1">
                <div class="col-lg-6">
                    <p class="font-weight-bold text-secondary">Type</p>
                </div>
                <div class="offset-lg-1 col-lg-5">
                    <p class="text-grey">{{ artwork?.digital ? 'Digital': 'Physical' }}</p>
                </div>
            </div>
            <hr class="m-0">
            <div class="row mt-2 p-1">
                <div class="col-lg-6">
                    <p class="font-weight-bold text-secondary">Production Date</p>
                </div>
                <div class="offset-lg-1 col-lg-5">
                    <p class="text-grey">{{ artwork?.year }}</p>
                </div>
            </div>
            <hr class="m-0">
            <div class="row mt-2 p-1">
                <div class="col-lg-6">
                    <p class="font-weight-bold text-secondary">Editions</p>
                </div>
                <div *ngIf="version" class="offset-lg-1 col-lg-5">
                    <p class="text-grey">{{ version?.version }}/{{ artwork?.edition }}</p>
                </div>
                <div *ngIf="!version" class="offset-lg-1 col-lg-5">
                    <p *ngIf="artwork?.version != 'sold_out'" class="text-grey">{{ artwork?.version.version }}/{{ artwork?.edition }}</p>
                    <p *ngIf="artwork?.version == 'sold_out'" class="text-grey">Sold out - {{ artwork?.edition }}/{{ artwork?.edition }}</p>
                </div>
            </div>
            <hr class="m-0">
            <div *ngIf="artwork?.tags.length > 0" class="row mt-2 p-1">
                <div class="col-lg-6 col-md-5">
                    <p class="font-weight-bold text-secondary">Tags</p>
                </div>
                <div class="offset-lg-1 col-lg-5">
                    <p class="text-grey d-inline-block float-left mr-1" *ngFor="let tag of artwork?.tags">
                        <span class="bg-light p-2" style="border-radius: 9px;">{{ tag.title }}</span>
                    </p>
                </div>
            </div>
            <hr class="m-0">
        </div>
    </div>
</div>