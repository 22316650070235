import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {  NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-modal-institution-edit-artwork',
  templateUrl: './modal-institution-edit-artwork.component.html',
  styleUrls: ['./modal-institution-edit-artwork.component.scss']
})
export class ModalInstitutionEditArtworkComponent implements OnInit {
  
  @Input() artwork: any
  public tags: string = '';
  public selectedStyle: any;
  public styles: any;
  
  
  constructor(
    public modalService: NgbModal,
    public api: ApiService,
    public router: Router,
    ) { }
    
    async ngOnInit() {
      this.api.get('artworks/styles').then((response) => {
        this.styles = response;
      });
      this.selectedStyle = this.artwork.style_id
      for await (let tag of this.artwork.tags) {
        this.tags += tag.title+", "
      }
      this.tags = this.tags.slice(0,-2)
    }

    updateArtwork(){
      this.artwork.tags = this.tags.split(",")
      this.artwork.style_id = this.selectedStyle
      this.api.put('artworks/'+ this.artwork.id, this.artwork).then((response)=>{
        Swal.fire(
          'Updated!',
          'Your artwork has been updated.',
          'success',
        )
          .then((response) => {
            if (response.isConfirmed) {
              document.location.reload();
            }
          })
      })
      console.log(this.artwork)
    }
    
    buttonClose(){
      const modalRef = this.modalService.dismissAll();
    }
  }
  