<div>
    <div class="modal-header justify-content-center">
        <h2 class="text-secondary font-weight-bold mt-2">Edit Artwork </h2>
        <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0 p-2">
            <img src="/assets/icons/close.svg">
        </button>
    </div>
    <div class="container modal-body">
        <div class="row">
            <p class="px-md-5 mx-5 text-center font-weight-light text-grey-light invited-p">After the first sell, some
                fields won´t be available to be edited.</p>
        </div>
        <div class="row d-flex justify-content-between mt-3">
            <div class="col-lg-3">
                <img [src]="artwork.img" style="width: 203px; border-radius: 9px; object-fit: cover;" alt="">
            </div>
            <div class="col-lg-7 pl-4">
                <div class="">
                    <p class="text-grey-link font-weight-light mb-1">Title</p>
                    <input [(ngModel)]="artwork.title" class="bg-light border-0 col py-4" type="text" required checked>
                </div>
                <div class="d-flex mt-3">
                    <div class="mr-2">
                        <p class="text-grey-link font-weight-light mb-1">Width</p>
                        <input [(ngModel)]="artwork.width" class="bg-light border-0 col py-4" placeholder="mm" type="text" required checked>
                    </div>

                    <div class="mx-2">
                        <p class="text-grey-link font-weight-light mb-1">Height</p>
                        <input [(ngModel)]="artwork.height" class="bg-light border-0 col py-4" placeholder="mm" type="text" required checked>
                    </div>
                    <div class="mx-2">
                        <p class="text-grey-link font-weight-light mb-1">Year</p>
                        <input  [(ngModel)]="artwork.year" class="bg-light border-0 col py-4" type="text" required checked>
                    </div>
                </div>
                <div class="mt-3">
                    <p class="text-grey-link font-weight-light mb-1">Description</p>
                    <textarea  [(ngModel)]="artwork.about" class="form-control bg-light border-radius-default border-0 w-100"
                        id="exampleFormControlTextarea1" rows="3"></textarea>
                </div>
                <div ngbDropdown class="d-inline-block w-100">
                    <p class="text-grey-link font-weight-light mb-2">Style</p>
                    <ng-select [(ngModel)]="selectedStyle" [placeholder]="'Style'" class="custom">
                        <ng-option *ngFor="let style of styles" [value]="style.id">{{style.title}}</ng-option>
                    </ng-select>
                </div>

                <div class="">
                    <p class="text-grey-link font-weight-light mb-1">Medium</p>
                    <input [(ngModel)]="artwork.medium" class="bg-light border-0 col py-4" type="text" required checked>
                </div>
                <div class="mt-3">
                    <p class="text-grey-link font-weight-light mb-1">Tags</p>
                    <textarea [(ngModel)]="tags" class="form-control bg-light border-radius-default border-0 w-100"
                        id="exampleFormControlTextarea1" rows="3">
                    </textarea>
                </div>
            </div>

        </div>
    </div>
    <div class="container mt-4">
        <div class="row modal-footer d-flex justify-content-start px-0">
            <div class="col-lg-3 px-0">
                <button class="w-100 btn btn-md text-primary font-weight-regular text-left cursor-pointer" (click)="buttonClose()">Cancel</button>
            </div>
            <div class="col-lg-4 px-0 ml-4">
                <button (click)="updateArtwork()" class="w-100 btn btn-lg btn-primary font-weight-regular cursor-pointer">Done</button>
            </div>
        </div>
    </div>
</div>
