<div class="titles-card-dashboard d-flex justify-content-between align-items-center mb-2">
    <div class="all-titles d-flex">
        <a class="h2 text-grey font-weight-light" [routerLink]="['/dashboard-creator/overview']" [class.disbaled]="disabledChart" routerLinkActive="selected">Overview</a>
        <a class="h2 text-grey font-weight-light" [routerLink]="['/dashboard-creator/artworks']" [class.disbaled]="disabledChart" routerLinkActive="selected">Artworks</a>
        <a class="h2 text-grey font-weight-light" [routerLink]="['/dashboard-creator/folders']" [class.disbaled]="disabledChart" routerLinkActive="selected">Folders</a>
        <a class="h2 text-grey font-weight-light" [routerLink]="['/dashboard-creator/trades']" [class.disbaled]="disabledChart" routerLinkActive="selected">Trades</a>
        <a class="h2 text-grey font-weight-light" [routerLink]="['/dashboard-creator/profile']" [class.disbaled]="disabledChart" routerLinkActive="selected">Profile</a>
    </div>
    <div class="buttons">
        <a class="text-grey font-weight-light share-profile cursor-pointer mr-3" [class.disbaled]="disabledChart" routerLinkActive="selected" (click)="shareProfile()">Share Profile</a>
        <a class="btn btn-secondary btn-create text-white px-4 py-2 my-1" *ngIf="!disabledChart && this.auth.userData.subscription && viewMode == 'overview' || viewMode == 'artworks'" [routerLink]="['/dashboard-creator/artworks-create']">Create an Artwork</a>
    </div>
</div>
<div *ngIf="!auth.userData.stripe_account_complete">
    <app-create-account-stripe></app-create-account-stripe>
</div>