<div class="container-fluid">
    <div class="row ml-2 mr-2">
        <div class="col mt-4">
            <p class="mb-1 text-grey"><a [routerLink]="['/purchased-works']">Your Vault</a> / {{ version?.artwork.title }}</p>
            <h1 class="text-primary font-weight-bold mb-1">{{ version?.artwork.title }}</h1>
            <p class="text-grey mb-5">{{ version?.artwork.artist.full_name }}</p>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row ml-md-2 mr-md-2 pb-5">
        <div class="col-md-4 col-lg-3 mb-5">
            <app-versionSpecs [version]="version" [artwork]="version?.artwork"></app-versionSpecs>
        </div>
        <div class="col-md-8 col-lg-9">
            <div class="card">
                <div class="row card-header bg-transparent d-flex justify-content-between">
                    <div class="mt-2 mb-2 d-flex titles-version">
                        <a [routerLink]="['/versions/'+version?.id+'/value']" routerLinkActive="selected" id="titles-card-versions"  class="text-grey font-weight-normal h4 card-title nav-link">Value</a>
                        <a [routerLink]="['/versions/'+version?.id+'/record']" routerLinkActive="selected" id="titles-card-versions" class="text-grey font-weight-normal h4 card-title nav-link">Record</a>
                        <a [routerLink]="['/versions/'+version?.id+'/about']" routerLinkActive="selected" id="titles-card-artworks" class="text-grey font-weight-normal h4 card-title nav-link">About</a>
                    </div>
                    <div class="d-flex mt-2 text-right justify-content-end align-items-center">
                        <qrcode [qrdata]="'https://beta.alphaa.io/?tradeQr='+version?.nft_token" [width]="70" [errorCorrectionLevel]="'M'" [elementType]="'svg'"></qrcode>

                        <!-- <img src="../../../assets/img/qrcode.jpeg" class="mr-3" width="40px" alt=""> -->
                        <button type="button" (click)="openTransaction()"  class="btn btn-primary ml-3 pl-4 pr-4 cursor-pointer">Trade</button>
                    </div>
                </div>
                
                <div class="card-body" *ngIf="viewMode === 'value'">
                    <div>
                        <h5 class="font-weight-bold text-secondary">Current Value</h5>
                        <h1 class="text-primary font-weight-normal"> {{ helper.amountCurrencyFormat(version?.price) }}</h1>
                    </div>
                    <div class="mt-5" *ngIf="chartOptions.chart" >
                        <h4 class="font-weight-bold text-secondary">Fluctuation</h4>
                        <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions" style="width: 100%; height: 300px; display: block;"></highcharts-chart>
                    </div>
                </div>
                <div class="card-body" *ngIf="viewMode === 'record'">
                    <div class="row">
                        <div class="col-lg-12">
                            <h4 class="text-secondary font-weight-bold">Details</h4>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-lg-12 col-md-6">
                            <div class="row">
                                <div class="col-lg-4">
                                    <p class="text-secondary font-weight-bold">Privacy setting</p>
                                </div>
                                <div class="col-lg-8">
                                    <p class="text-grey font-weight-light">Private</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-6">
                            <div class="row">
                                <div class="col-lg-4">
                                    <p class="text-secondary font-weight-bold">Artist</p>
                                </div>
                                <div class="col-lg-8">
                                    <p class="text-grey font-weight-light mb-2">{{ version?.artwork.artist.full_name }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-4 col-md-6">
                                    <p class="text-secondary font-weight-bold">Hash Token</p>
                                </div>
                                <div class="col-lg-8 col-md-6">
                                    <p class="text-grey font-weight-light mb-2">{{ version?.fake_token }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="row mt-3">
                        <div class="col-lg-12 mt-4">
                            <h3 class="text-secondary font-weight-bold">In progress</h3>
                            <p class="text-grey font-weight-light"> Lorem ipsun dolor sit amet Lorem ipsin dolor sit amet.</p>
                        </div>
                    </div> -->

                    <!-- <app-trade-status></app-trade-status> -->

                    <!-- <hr> -->
                    <!-- <div class="row mt-3">
                        <div class="col-lg-12 mt-4">
                            <h5 class="text-secondary font-weight-bold">Recent</h5>
                            <p class="text-grey font-weight-light"> Lorem ipsun dolor sit amet Lorem ipsin dolor sit amet.</p>
                        </div>
                    </div> -->
                    <hr>
                    <div class="row">
                        <div class="col-md-8 col-lg-8">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 mt-sm-2">
                                    <img src="/assets/img/purchased-green.png" style="width: 4em;" alt="update">
                                </div>
                                <div class="col-lg-9 col-md-9">
                                    <h5 class="mb-0 mt-3 text-secondary">Purchased by <span class="font-weight-bold">{{ version?.owner.full_name }}</span></h5>
                                    <p class="text-grey">{{ helper.formatDate(version?.updated_at) }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-4">
                            <button type="button" (click)="openYourSmartContract(version)" class="border-0 rounded p-1 px-0 text-grey">
                                <img src="/assets/icons/transaction.png">
                                Smart Contract
                            </button>
                        </div>
                    </div>
                    <hr>
                    <div class="row mt-3">
                        <div class="col-lg-12 mt-4">
                            <h5 class="text-secondary font-weight-bold">History</h5>
                            <p class="text-grey font-weight-light">Through alphaa.io's digital NFT certificates the full artwork history <br> and transaction log can be easily viewed</p>
                        </div>
                    </div>
                    <div *ngFor="let history of histories">
                        <hr>
                        <div class="row">
                            <div class="col-lg-8 col-md-8">
                                <div class="row">
                                    <div class="col-lg-3 col-md-3 mt-sm-2">
                                        <img src="assets/img/purchased.png" alt="purchased">
                                    </div>
                                    <div class="col-lg-9 col-md-9">
                                        <h5 class="mb-0 mt-3 text-secondary">{{ textStatus(history.status) }} by <span class="font-weight-bold">{{ textSubject(history) }}</span></h5>
                                        <p class="text-grey">{{ helper.formatDate(history.created_at) }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 mt-sm-3 px-0">
                                <button type="button" (click)="openTransactionTwo(version)"  class="btn text-grey px-0 cursor-pointer"><img src="/assets/icons/transaction.png"> Transaction Data</button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <hr>
                        <div class="row">
                            <div class="col-lg-8 col-md-8">
                                <div class="row">
                                    <div class="col-lg-3 col-md-3 mt-2">
                                        <img src="assets/img/create.png" alt="create">
                                    </div>
                                    <div class="col-lg-9 col-md-9">
                                        <h5 class="mb-0 mt-3 text-secondary">Created by <span class="font-weight-bold">{{ version?.artwork.artist.full_name }}</span></h5>
                                        <p class="text-grey">{{ helper.formatDate(version?.created_at) }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 mt-sm-3 px-0">
                                <button type="button" (click)="openTransactionTwo(version)"  class="btn text-grey px-0 cursor-pointer"><img src="/assets/icons/transaction.png"> Transaction Data</button>
                            </div>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="card-body" *ngIf="viewMode === 'about'">
                    <div class="row">
                        <div class="col-lg-12">
                            <h4 class="text-secondary font-weight-bold">About This Artwork</h4>
                            <div class="row">
                                <div class="col-lg-9 mt-2">
                                    <b>More about this artwork</b>
                                    <p [innerHTML]="version?.about" class="text-grey"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>