<section id="your-smart">
    <div class="text-center pb-4 container">
        <div class="row p-3">
            <div class="col-lg-12 pt-4">
                <h4 class="text-secondary font-weight-bold">Your Smart Contract</h4>
                <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0">
                    <img src="/assets/icons/close.svg">
                </button>
            </div>
        </div>
        <hr>
        <div class="row p-3">
            <div class="col-lg-12 col-md-12 text-justify-center pr-3 pl-4">
                <div>
                    <h4 class="text-secondary font-weight-bold">{{version.artwork.title}}</h4>
                    <p class="text-grey pb-5">By {{version.artwork.artist.full_name}}</p>
                </div>
                <hr>
                <img [src]="version.artwork.img" class="rounded" style="width: 10em;">
                <hr>
            </div>
            <div class="col-lg-12 col-md-12 text-sm-left px-0" *ngIf="version">
                <div class="row mt-5  p-3">
                    <div class="col-lg-12 col-md-6">
                        <div class="row">
                            <div class="col-lg-6">
                                <p class="text-secondary font-weight-bold">Creation date</p>
                                <p class="text-secondary font-weight-bold">Size</p>
                                <p class="text-secondary font-weight-bold">Edition</p>
                            </div>
                            <div class="col-lg-6">
                                <p class="text-secondary">{{helper.formatDate(version.created_at)}}</p>
                                <p class="text-secondary">{{version.artwork.width}} x {{version.artwork.height}} {{version.artwork.unit}}</p>
                                <p class="text-secondary">{{version.version}} / {{version.artwork.edition}}</p>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="col-lg-12 col-md-6">
                        <div class="row">
                            <div class="col-lg-6">
                                <p class="text-secondary font-weight-bold">Buyer</p>
                                <p class="text-secondary font-weight-bold">Transaction Date</p>
                            </div>
                            <div class="col-lg-6">
                                <p class="text-secondary">{{version.owner.full_name}}</p>
                                <p class="text-secondary">{{helper.formatDate(version.created_at)}}</p>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="col-lg-12 col-md-6">
                        <div class="row">
                            <div class="col-lg-6">
                                <p class="text-secondary font-weight-bold">Royalties</p>
                                <p class="text-grey ">Applied on every trade
                            </div>
                            <div class="col-lg-6">
                                <p class="text-secondary">{{version.artwork.author_fee / 100}}% artist</p>
                                <p class="text-secondary">{{version.artwork.institution_fee / 100}}% institution</p>
                                <p class="text-secondary">{{version.artwork.platform_fee / 100}}% Alpha’a platform</p>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="col-lg-12 col-md-6">
                        <div class="row">
                            <div class="col-lg-6">
                                <p class="text-secondary font-weight-bold">Actual Price</p>
                            </div>
                            <div class="col-lg-6">
                                <h4 class="text-primary font-weight-normal">$ {{version.price / 100}}</h4>
                                <p class="text-grey">Updated on today at {{helper.formatDate(version.updated_at)}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <hr>
    <div class="p-3 text-left">
        <!-- <button type="button" href="#" class="btn btn-outline pl-4 pr-4 text-primary">Download PDF <img src="/assets/icons/pdf.png">
        </button>
        <button type="button" href="#" class="btn btn-primary font-weight-bold pl-5 pr-5" style="width: 10em;">Done</button> -->
    </div>
</section>