import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApiService } from './services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalTradeBuyerComponent } from './modals/modal-trade-buyer/modal-trade-buyer.component';
import { ModalTradeBuyerQrComponent } from './modals/modal-trade-buyer-qr/modal-trade-buyer-qr.component';
import Swal from 'sweetalert2';
import { ModalInviteInstitutionComponent } from './modals/modal-invite-institution/modal-invite-institution.component';
import { AuthService } from './services/auth.service';
import { ModalRequestManegeComponent } from './modals/modal-request-manege/modal-request-manege.component';
import { ModalRequestManegentArtworkComponent } from './modals/modal-request-manegement-artwork/modal-request-manegement-artwork.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'alphaio';
  
  public tradeModalContent: any = {};
  
  constructor(
    public auth: AuthService,
    public api: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    public modalService: NgbModal,
    private gtmService: GoogleTagManagerService,
    ) {
      this.router.events.forEach(item => {
        if (item instanceof NavigationEnd) {
          const gtmTag = {
            event: 'page',
            pageName: item.url
          };
          this.gtmService.pushTag(gtmTag);
        }
      });
      this.gtmService.addGtmToDom();
    }
    
    ngOnInit() {
      window.addEventListener('load', async () => {
        let video = document.querySelector('video[muted][autoplay]');
        for(let i in video){
          try {
            await video[i].mute();
            await video[i].play();
          } catch (err) {
            video[i].controls = true;
          }
        }
      });
      
      const gtmTag = {
        event: 'page',
        pageName: this.route.url
      };
      
      this.gtmService.pushTag(gtmTag);
      
      this.route.queryParams.subscribe(params => {
        if (params['requestManegementArtwork'] && params['it']) {
          let modal = this.modalService.open(ModalRequestManegentArtworkComponent)
          modal.componentInstance.artwork_id = atob(params['requestManegementArtwork']);
          modal.componentInstance.institution_id = atob(params['it']);
        }
        
        if (params['requestManegement']) {
          this.api.get('institutions/invite/' + atob(params['requestManegement'])).then((data) => {
            let modal = this.modalService.open(ModalRequestManegeComponent)
            modal.componentInstance.invite = data;
          }).catch((e) => {
            Swal.close();
          })
        }
        if (params['invite'] && !params['email'] && this.router.url.search('authenticate-register') < 0) {
          this.api.get('institutions/invite/' + atob(params['invite'])).then((data) => {
            let modal = this.modalService.open(ModalInviteInstitutionComponent)
            modal.componentInstance.invite = data;
          }).catch((e) => {
            Swal.close();
          })
        }
        if (params['trade']) {
          this.api.get('transfers/' + params['trade']).then((data) => {
            let modal = this.modalService.open(ModalTradeBuyerComponent)
            modal.componentInstance.transaction = data;
            modal.componentInstance.success = params['success'];
          }).catch((e) => {
            Swal.close();
            let modal = this.modalService.open(ModalTradeBuyerComponent)
            modal.componentInstance.tradeBuyerStep = 0;
            // return Promise.reject(e)
          })
        }
        if (params['tradeQr']) {
          this.api.get('versions/getByNftToken/' + params['tradeQr']).then((data) => {
            console.log(data)
            let modal = this.modalService.open(ModalTradeBuyerQrComponent)
            modal.componentInstance.version = data;
            modal.componentInstance.success = params['success'];
          }).catch((e) => {
            console.log(e.error)
            if(e.error == 'E_FIND_ENTITY: Cannot find data with given parameters' || e.error == 'access_denied'){
              return Promise.reject(e)
            }else{
              Swal.close();
              let modal = this.modalService.open(ModalTradeBuyerQrComponent)
              modal.componentInstance.tradeBuyerStep = 0;
              // return Promise.reject(e)
            }
          })
        }[]
      });
      
    }
    
  }
  