<div class="modal-header d-flex justify-content-center align-items-center">
    <h4 class="modal-title font-weight-bold" id="modal-basic-title">Leave feedback</h4>
    <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0">
        <img src="/assets/icons/close.svg">
    </button>
</div>
<div class="modal-body p-4">
    <p>How was your experience with the seller?</p>
        <p class="text-secondary font-weight-bold text-center m-0">Rate the seller</p>
        <ngb-rating id="rating" [(rate)]="currentRate">
            <ng-template let-fill="fill" let-index="index">
              <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3">&#9733;</span>
            </ng-template>
        </ngb-rating>
    <textarea [(ngModel)]="comment" class="form-control bg-light border-0 p-2 mt-5 mx-2" placeholder="Add a comament" name="" id="" cols="50" rows="4"></textarea>
</div>
<div class="modal-footer d-flex justify-content-start">
    <a (click)="buttonClose()" class="text-primary mx-4">Do it later</a>
    <button type="button"  (click)="saveFeedback()" class="btn btn-lg btn-primary mx-5 px-5 cursor-pointer">Done</button>
</div>