import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './pages/about/about.component';
import { ArtworkComponent } from './pages/artwork/artwork.component';
import { AuthComponent } from './pages/auth/auth.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DashboardCreatorComponent } from './pages/dashboard-creator/dashboard-creator.component'
import { FoldersComponent } from './pages/folders/folders.component';
import { HomeComponent } from './pages/home/home.component';
import { HomeCollectComponent } from './pages/home-collect/home-collect.component';
import { HomeCreatorComponent } from './pages/home-creator/home-creator.component'
import { ListsComponent } from './pages/lists/lists.component';
import { SubscribeComponent } from './pages/subscribe/subscribe.component';
import { VersionComponent } from './pages/version/version.component';
import { YourArtComponent } from './pages/your-art/your-art.component';
import { TradesComponent } from './pages/trades/trades.component';
import { LearnComponent } from './pages/learn/learn.component';
import { AuthGuard } from './guards/auth.guard';
import { DashboardInstitutionComponent } from './pages/dashboard-institution/dashboard-institution.component'
import { SubscriptionGuard } from './guards/subscription.guard';
import { ModalCreateArtworkComponent } from './modals/modal-create-artwork/modal-create-artwork.component';
import { ProfileInstitutionComponent } from './pages/profile-institution/profile-institution.component';
import { ArtistDetailComponent } from './pages/artist-detail/artist-detail.component';
import { EventArtworkDetailsComponent } from './pages/event-artwork-details/event-artwork-details.component';
import { EventComponent } from './pages/event/event.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { ReturnStripeRegisterComponent } from './pages/return-stripe-register/return-stripe-register.component';


const routes: Routes = [
  { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
  { path: 'auth/:viewMode', component: AuthComponent },
  { path: 'dashboard', redirectTo: 'dashboard/artworks'},
  { path: 'dashboard/:viewMode', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'dashboard-creator', redirectTo: 'dashboard-creator/artworks', pathMatch: 'full' },
  { path: 'dashboard-creator/:viewMode', component: DashboardCreatorComponent, canActivate: [AuthGuard] },
  { path: 'institution/:slug', component: ProfileInstitutionComponent },
  { path: 'dashboard-institution', component: DashboardInstitutionComponent, canActivate: [AuthGuard] },
  { path: 'dashboard-institution/:viewMode', component: DashboardInstitutionComponent, canActivate: [AuthGuard] },
  { path: 'event/:slug', component: EventComponent },
  { path: 'event/:slug/:artwork_id', component: EventArtworkDetailsComponent},
  { path: 'folders', component: FoldersComponent, canActivate: [AuthGuard] },
  { path: 'folders/:gallery', component: FoldersComponent, canActivate: [AuthGuard] },
  { path: 'lists', component: ListsComponent, canActivate: [AuthGuard] },
  { path: 'lists/:gallery', component: ListsComponent, canActivate: [AuthGuard] },
  { path: 'subscribe', component: SubscribeComponent, canActivate: [AuthGuard] },
  { path: 'subscribe/:activePlans', component: SubscribeComponent, canActivate: [AuthGuard] },
  { path: 'trades', component: TradesComponent, canActivate: [AuthGuard] },
  { path: 'versions/:id', component: VersionComponent },
  { path: 'versions/:id/:viewMode', component: VersionComponent},
  { path: 'artworks/:id', component: ArtworkComponent },
  { path: 'artworks/:id/:viewMode', component: ArtworkComponent},
  { path: 'purchased-works', component: YourArtComponent, canActivate: [AuthGuard] },
  { path: 'learn', component: LearnComponent },
  { path: 'about', component: AboutComponent },
  { path: 'return-stripe-register', component: ReturnStripeRegisterComponent },
  { path: 'home', component: HomeComponent },
  { path: 'home-collect', component: HomeCollectComponent },
  { path: 'home-creator', component: HomeCreatorComponent },
  { path: 'profile-institution', component: ProfileInstitutionComponent },
  { path: 'artist-detail/:slug', component: ArtistDetailComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
