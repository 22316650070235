<div *ngIf="filters">
    <div class="row my-3">
        <div class="col-lg-12">
            <h1 class="font-weight-bold text-secondary">Artworks</h1>
        </div>
    </div>
    <div class="row align-item-center">
        <div class="col-xl-5 col-lg-5">
            <div class="row tags-dash d-flex">
                <a (click)="changeStatusFilter('digital')" [class.active]="activeStatusFilter == 'digital'" class="nav-link text-grey font-weight-light cursor-pointer">Digital</a>
                <a (click)="changeStatusFilter('physical')" [class.active]="activeStatusFilter == 'physical'" class="nav-link text-grey font-weight-light cursor-pointer">Physical</a>
                <a (click)="changeStatusFilter('all')" [class.active]="activeStatusFilter == 'all'" class="nav-link text-grey font-weight-light cursor-pointer">All</a>
            </div>
        </div>
        <div class="col-xl-7 col-lg-7">
            <div class="row justify-content-between">
                <div class="col-lg-8 col-md-7 d-flex align-items-center px-lg-0 mt-2">
                    <input [(ngModel)]="searchFilter" (ngModelChange)="searchItem($event)" class="input-search form-control border-0 w-100" type="text" placeholder="Search" checked>
                    <!-- <button (click)="filterItems()" type="button" class="btn btn-primary btn-search d-flex justify-content-center align-items-center position-absolute">Search</button> -->
                </div>
                <div class="col-lg-3 col-sm-6 d-flex justify-content-lg-end mt-2">
                    <ng-select [(ngModel)]="order" (change)="changeOrder(order)" placeholder="Order by&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;">
                        <ng-option *ngFor="let option of optionsOrderBy" [value]="option.id">{{option.name}}&nbsp;&nbsp;</ng-option>
                     </ng-select>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid dash-initial">
    <div class="row">
        <div *ngIf="auth.isAuthenticated.getValue() && data.length == 0" class="col-lg-12 d-flex flex-wrap p-0">
            <div class="text-center my-5 py-5">
                <h1>Create your first Artwork</h1>
                <p>You can begin uploading your artwork here. Please check your file size and format before uploading. For physical works, please prepare a digital clone of the item which will be converted into an NFT. For Digital artworks upload the digital form, such as jpegs, mpegs, etc.</p>
                <button type="button" class="btn btn-primary" [routerLink]="[linkCreateArtwork]">Submit Artwork</button>
            </div>
        </div>
        
        <div *ngIf="data" class="col-lg-12 d-flex flex-wrap p-0">
            <ng-container *ngIf="link == 'artworks'">
                <div class="col-auto my-3 p-0" *ngFor="let item of data">
                    <app-artworks-card [title]="title" [link]="link" [artwork]="item"></app-artworks-card>
                </div>
            </ng-container>
            
            <ng-container *ngIf="link == 'versions'">
                <ng-container *ngFor="let item of data">
                    <div class="col-auto my-3 p-0" *ngFor="let version of item.versions">
                        <app-artworks-card [title]="title" [link]="link" [artwork]="item" [version]="version"></app-artworks-card>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <div class="col-lg-12 text-center dash-initial my-5" *ngIf="meta">
            <button (click)="loadMore()" type="button" *ngIf="meta.current_page < meta.last_page" class="btn btn-outline-primary font-weight-bold pl-3 pr-3 mr-3 load-more">Load more ({{meta.current_page}}/{{meta.last_page}})</button>
        </div>
    </div>
</div>















<!-- 
    
    <div class="container-fluid dash-initial" *ngIf="type == 'dashboard-collector'">
        <div class="row">
            <div class="col-lg-12 p-0">
                <div class="d-flex flex-wrap">
                    <div class="box-artwork mt-3" *ngFor="let version of versions" style="position: relative;">
                        <app-dropdown-to-artworks [artwork_version_id]="version.id" class="dropdown-to-artworks"></app-dropdown-to-artworks>
                        <a class="nav-link d-block px-0" [routerLink]="['/versions/'+version.id]">
                            <app-preview-artwork [img]="version.artwork.preview" [class]="'square'"></app-preview-artwork>
                            <p class="text-secondary font-weight-bold mt-1 mb-0">{{ version.artwork.title }}</p>
                            <p class="text-secondary font-weight-regular mb-0">US$ {{(version.artwork.original_price/100).toFixed(2) }}</p>
                            <small class="font-weight-light text-grey">Uploaded {{ helper.formatDate(version.artwork.created_at) }}</small>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="type == 'dashboard'">
        <div>
            <div class="row my-3">
                <div class="col-lg-12">
                    <h1 class="font-weight-bold text-secondary">Your Artworks</h1>
                </div>
            </div>
            <div class="row align-item-center">
                <div class="col-xl-5 col-lg-5">
                    <div class="row tags-dash d-flex">
                        <a (click)="activeArtworkStatusFilter = 'digital'" [class.active]="activeArtworkStatusFilter == 'digital'" class="nav-link text-grey font-weight-light cursor-pointer">Digital</a>
                        <a (click)="activeArtworkStatusFilter = 'physical'" [class.active]="activeArtworkStatusFilter == 'physical'" class="nav-link text-grey font-weight-light cursor-pointer">Physical</a>
                        <a (click)="activeArtworkStatusFilter = 'all'" [class.active]="activeArtworkStatusFilter == 'all'" class="nav-link text-grey font-weight-light cursor-pointer">All</a>
                    </div>
                </div>
                <div class="col-xl-7 col-lg-7">
                    <div class="row justify-content-lg-end mt-2">
                        <div class="col-lg-9 col-md-7 d-flex align-items-center">
                            <input [(ngModel)]="artworkSearchFilter" class="input-search form-control border-0 w-100" type="text" placeholder="Search" checked>
                            <button type="button" class="btn btn-primary btn-search d-flex justify-content-center align-items-center position-absolute">Search</button>
                        </div>
                        <div class="col-3 d-md-flex justify-content-end">
                            <app-order-by-institution></app-order-by-institution>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-wrap justify-content-left">
            <app-institution-from-artworks [artworks]="artworks" [versions]="artworks" [activeArtworkStatusFilter]="activeArtworkStatusFilter" [artworkSearchFilter]="artworkSearchFilter"></app-institution-from-artworks>
        </div>
    </div> -->