<div ngbDropdown placement="top-end" class="d-inline-block">
    <button type="button" class="btn d-flex btn-share" id="dropdownBasic2" ngbDropdownToggle>
        <img src="/assets/icons/share-blue.png" alt="">
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
        <button ngbDropdownItem class="d-flex justify-content-center align-items-center p-0">
            <img src="/assets/icons/icon-instagram.png" alt="">
        </button>
        <button ngbDropdownItem class="d-flex justify-content-center align-items-center p-0">
            <img src="/assets/icons/icon-twitter.png" alt="">
        </button>
        <button ngbDropdownItem class="d-flex justify-content-center align-items-center p-0">
            <img src="/assets/icons/icon-facebook.png" alt="">
        </button>
        <button ngbDropdownItem class="d-flex justify-content-center align-items-center p-0">
            <img src="/assets/icons/icon-whatsapp.png" alt="">
        </button>
        <button ngbDropdownItem class="d-flex justify-content-center align-items-center p-0">
            <img src="/assets/icons/icon-in.png" alt="">
        </button>
    </div>
</div>
