import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {  NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-institution-add-artists',
  templateUrl: './modal-institution-add-artists.component.html',
  styleUrls: ['./modal-institution-add-artists.component.scss']
})
export class ModalInstitutionAddArtistsComponent implements OnInit {
  
  @Input() invite: any;
  public infoRoyalty: boolean;
  public infoEscrwo: boolean;
  public InstitutionAddStep: number = 0;
  public artists: any = [
    {
      institution_fee_amount: 5,
      institution_escrow_amount: 10
    }
  ];
  
  constructor(
    public modalService: NgbModal,
    public router: Router,
    public api: ApiService,
    private auth: AuthService,
    ) { }
    
    ngOnInit(): void {
      if(this.invite){
        this.invite.institution_escrow_amount = this.invite.institution_escrow_amount/100
        this.invite.institution_fee_amount = this.invite.institution_fee_amount/100
      }
    }
    
    addAnotherArtist(){
      this.artists.push({
        institution_fee_amount: 5,
        institution_escrow_amount: 10
      });
    }
    
    removeArtist(index: any){
      this.artists.splice(index,1);
    }
    
    updateInvite(){
      delete this.invite.institution
      delete this.invite.creator
      this.api.put('institutions/invite/'+this.invite.id, this.invite).then((response) => {
        Swal.fire(
          'Updated!',
          'Your invite has been successfully updated',
          'success',
          )
          .then((response) => {
            if (response.isConfirmed) {
              window.location.reload()
            }
          })
        })
        
      }
      
      sendInvite(){
        this.api.post('institutions/'+this.auth.userData.id+'/inviteArtist', this.artists).then((response) => {
          let text = ''
          for(let erro of response){
            text += erro.email + "<br>"
          }
          if(response.length > 0){
            Swal.fire({
              title: 'Ops!',
              html: "<p>These users are not registered or are not artists:<br>"+text+"</p>",
              icon: 'warning',
            }).then((response)=>{
              this.buttonClose()
            })
          }else{
            Swal.fire(
              'Invited!',
              'Your invitations have been sent successfully',
              'success',
              )
              .then((response) => {
                if (response.isConfirmed) {
                  this.buttonClose()
                }
              })
            }
          })
        }
        
        buttonClose(){
          const modalRef = this.modalService.dismissAll();
        }
      }
      