import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalTradeDigitalComponent } from 'src/app/modals/modal-trade-digital/modal-trade-digital.component';
import { ApiService } from 'src/app/services/api.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-dropdown-options-remove-trade',
  templateUrl: './dropdown-options-remove-trade.component.html',
  styleUrls: ['./dropdown-options-remove-trade.component.scss']
})
export class DropdownOptionsRemoveTradeComponent implements OnInit {
  @Input() list: any;
  @Input() folder: any;
  @Input() version: any;
  @Input() artwork: any;
  @Input() id: any;

  constructor(
    public api: ApiService,
    public modalService: NgbModal
  ) { }

  ngOnInit(): void {
  }

  openTransactionList(version_id) {
    const modalRef = this.modalService.open(ModalTradeDigitalComponent);
    modalRef.componentInstance.artwork_version_id = version_id;
  }
  openTransactionFolder(artwork_id) {
    const modalRef = this.modalService.open(ModalTradeDigitalComponent);
    modalRef.componentInstance.artwork_id = artwork_id;
  }
  acceptDeleteList(version_id: number, list_id: number): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d2ae4d',
      cancelButtonColor: '#c00303',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.api.delete('artworks/' + version_id + '/lists/' + list_id).then((response) => {
          Swal.fire(
            'Deleted!',
            'Your list has been deleted.',
            'success',
          )
            .then((response) => {
              if (response.isConfirmed) {
                document.location.reload();
              }
            })
        })
      }
    })
  }

  acceptDeleteFolder(artwork_id: number, folder_id: number): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d2ae4d',
      cancelButtonColor: '#c00303',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.api.delete('artworks/' + artwork_id + '/folders/' + folder_id).then((response) => {
          Swal.fire(
            'Deleted!',
            'Your folder has been deleted.',
            'success',
          )
            .then((response) => {
              if (response.isConfirmed) {
                document.location.reload();
              }
            })
        })
      }
    })
  }



}
function payload(arg0: string, payload: any) {
  throw new Error('Function not implemented.');
}
