<div class="container-fluid px-0">
    <div class="row">
        <div class="col-lg-12 px-0">
            <div class="d-flex">
                <button type="button" class="btn p-0 cursor-pointer" ><img src="/assets/icons/rede.png" alt="" style="width: 70px;"></button>
                <button type="button" class="btn p-0 cursor-pointer" > <img src="/assets/icons/instagram.png" alt="" style="width: 70px;"></button>
                <button type="button" class="btn p-0 cursor-pointer" ><img src="/assets/icons/facebook.png" alt="" style="width: 70px;"> </button>
                <button type="button" class="btn p-0 cursor-pointer" ><img src="/assets/icons/twitter.png" alt="" style="width: 70px;"> </button>
            </div>
        </div>
    </div>
</div>