import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts';

@Injectable({
  providedIn: 'root'
})

export class HighChartsService {

  chartOptions: Highcharts.Options = {
    chart: {
    },
    credits: null,
    colors: ['#d2ae4d', '#4c0d34'],
    title: {
      text: null
    },
    xAxis: {
      categories: [],
      title: {
        text: null
      },
    },
    yAxis: {
      title: {
        text: null
      },
      labels: {
        formatter: (point: any) => {
          let result = Math.round(point.value / 100) / 10;
          return "$" + result + 'K'
        }
      },
      opposite: true
    },
    tooltip: {
      valuePrefix: '$'
    },

    series: [{
      showInLegend: false,
      name: null,
      data: [],
      type: 'column'
    }]
  };


  constructor(
  ) {
  }

  populateSplineChart(data) {
    let options = Object.assign(this.chartOptions);
    options.chart.type = 'spline';
    options.series[0].type = 'spline';
    options.plotOptions = { spline: { lineWidth: 2 } };

    options.xAxis.categories = [];
    options.series[0].data = [];
    for (let i in data) {
      options.xAxis.categories.push(data[i].x);
      options.series[0].data.push(data[i].y);
    }
    return options;
  }

  populateColumnChart(data) {
    let options = Object.assign(this.chartOptions);
    options.chart.type = 'column';
    options.series[0].type = 'column';
    options.plotOptions = { column: { pointWidth: 2 } };

    options.xAxis.categories = [];
    options.series[0].data = [];
    for (let i in data) {
      options.xAxis.categories.push(data[i].x);
      options.series[0].data.push(data[i].y);
    }
    return options;
  }

}
