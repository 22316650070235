import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-institution-from-artists',
  templateUrl: './institution-from-artists.component.html',
  styleUrls: ['./institution-from-artists.component.scss']
})
export class InstitutionFromArtistsComponent implements OnInit {
   @Input() invites: any[];
   @Input() artist: any;

   constructor() {}
  
  ngOnInit(): void {
  }
   
}
