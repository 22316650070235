import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-auth-forms',
  templateUrl: './auth-forms.component.html',
  styleUrls: ['./auth-forms.component.scss']
})
export class AuthFormsComponent implements OnInit {
  
  public newPassword = false
  public confirmNewPassword = false
  public confirmPasswordLogin = false;
  public passwordType = 'password';
  public confirmPasswordType = 'password';
  public passwordLoginType = 'password'
  public selectedCountry: number
  
  
  @Input() form: string;
  @Input() redirect: boolean = true;
  @Output() onSubmit = new EventEmitter;

	separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  
  constructor(
    public auth: AuthService,
    public router: Router,
    private config: NgSelectConfig
    ) {
      this.config.notFoundText = 'Custom not found';
      this.config.appendTo = 'body';
      this.config.bindValue = 'value';
    }
    
    ngOnInit(): void {
    }
    
    
    _onSubmit() {
      console.log(this.redirect)
      console.log(this.form)
      let call;
      switch (this.form) {
        case 'register':
        call = this.auth.submitRegisterForm(this.auth.formRegister, this.redirect).then((response)=>{
          if(response.body == 'user created'){
            this.form = 'authenticate-register';
          }
        }).catch((e)=>{
          console.log(e)
        })
        this.onSubmit.emit({promise: call, form: 'register'});
        break;
        case 'login':
        call = this.auth.submitLoginForm(this.auth.formLogin, this.redirect).then((data) => {
          this.form = 'two-factor';
        }).catch((e) => {
          if (e.error == "account_not_confirmed") {
            this.form = 'authenticate-register';
          }
        });
        this.onSubmit.emit({promise: call, form: 'login'});
        break;
        case 'forgot-password':
        call = this.auth.forgotPassword(this.auth.formForgotPassword, this.redirect).then((data) => {
          this.form = 'login';
        }).catch((e) => {
          
        });
        this.onSubmit.emit({promise: call, form: 'forgot-password'});
        break;
        case 'recovery-password':
        call = this.auth.recoveryPassword(this.auth.formRecoveryPassword, this.redirect);
        this.onSubmit.emit({promise: call, form: 'recovery-password'});
        break;
        case 'two-factor':
        // console.log(this.redirect);
        call = this.auth.twoFactor(this.auth.formTwoFactor, this.redirect);
        this.onSubmit.emit({promise: call, form: 'two-factor'});
        break;
        case 'authenticate-register':
        call = this.auth.confirmEmail(this.auth.formTwoFactor, this.redirect);
        this.onSubmit.emit({promise: call, form: 'authenticate-register'});
        break;
      }
    }
    goToFogotPassword(){
      if(this.redirect){
        this.router.navigateByUrl('/auth/forgotPassword')
      }else{
        this.form = 'forgot-password'
      }
    }
    
    goToRegister(){
      if(this.redirect){
        this.router.navigateByUrl('/auth/register')
      }else{
        this.form = 'register'
      }
    }
    
    goToLogin(){
      if(this.redirect){
        this.router.navigateByUrl('/auth/login')
      }else{
        this.form = 'login'
      }
    }
    
    showPassword() {
      this.newPassword = !this.newPassword
      this.passwordType = this.newPassword ? 'text' : 'password'
    }
    
    showConfirmPassword() {
      this.confirmNewPassword = !this.confirmNewPassword
      this.confirmPasswordType = this.confirmNewPassword ? 'text' : 'password'
    }
    
    showPasswordLogin() {
      this.confirmPasswordLogin = !this.confirmPasswordLogin
      this.passwordLoginType = this.confirmPasswordLogin ? 'text' : 'password'
    }
    
    
    previusDigit(num, id) {
      let prevEl = document.getElementById(id + '-' + (num));
      prevEl.focus();
    }
    
    nextDigit(num, id, evt: any = false) {
      if(evt.keyCode == 8){
        let prevEl = document.getElementById(id + '-' + (num - 1));
        if (prevEl) {
          prevEl.focus();
        }
      }else if (num === null && evt != false && evt.type == 'paste') {
        evt.preventDefault();
        let pastedData = evt.clipboardData?.getData('Text');
        for (let i in pastedData) {
          if (parseInt(i) >= 6) break;
          let el = document.getElementById(id + '-' + (parseInt(i) + 1));
          this.auth.formTwoFactor.controls['input' + (parseInt(i) + 1)].patchValue(pastedData[i]);
        }
        if (id === 'confirm-email-input') {
          let call = this.auth.confirmEmail(this.auth.formTwoFactor, this.redirect);
          this.onSubmit.emit({promise: call, form: 'authenticate-register'});
        } else if (id === 'two-factor-input') {
          let call = this.auth.twoFactor(this.auth.formTwoFactor, this.redirect);
          this.onSubmit.emit({promise: call, form: 'two-factor'});
        }
        
      } else {
        let nextEl = document.getElementById(id + '-' + (num + 1));
        
        if (!this.auth.formTwoFactor.controls['input' + num].value) {
          return false
        }
        if (nextEl) {
          nextEl.focus();
        } else if (num === 6) {
          if (id === 'confirm-email-input') {
            let call = this.auth.confirmEmail(this.auth.formTwoFactor, this.redirect);
            this.onSubmit.emit({promise: call, form: 'authenticate-register'});
          } else if (id === 'two-factor-input') {
            let call = this.auth.twoFactor(this.auth.formTwoFactor, this.redirect);
            this.onSubmit.emit({promise: call, form: 'two-factor'});
          }
          
        }
      }
      return false;
    }
    
    
    
}
  