<main>
    <div class="container-fluid px-sm-5 dash-initial">
        <div class="row">
            <div class="col-lg-12">
                <p class="font-weight-light col-lg-12 mb-0 mt-4 pt-1 px-0">Your Vault</p>
                <h1 class="text-primary font-weight-bold">Welcome back, {{ auth.userData.full_name }}</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card border-0 mt-3 p-3">
                    <div class="row p-1">
                        <div class="col-lg-4 col-md-6">
                            <h5 class="font-weight-bold text-secondary mb-1">Art Vault net worth</h5>
                            <!-- <div *ngIf="versions?.length == 1">
                                <p class="text-grey font-weight-light">Including all {{ artworks?.length }} artwork</p>
                            </div> -->
                            <div>
                                <p class="text-grey font-weight-light">Including all your {{ versions?.length }} artworks</p>
                            </div>
                            <!-- <p class="text-grey font-weight-light">Including all {{ versions?.length }} versions</p> -->
                            <h3 class="text-primary display-3 py-2 mt-5">${{ total.toFixed(2) }}</h3>
                        </div>
                        <div class="col-lg-8 col-md-6">
                            <highcharts-chart *ngIf="chartOptions.chart" [Highcharts]="Highcharts" [options]="chartOptions" style="width: 100%; height: 300px; display: block;"></highcharts-chart>
                        </div>
                    </div>
                    <div>
                        <app-last-trades></app-last-trades>
                    </div>
                </div>
            </div>
        </div>
        <div class="row d-sm-flex justify-content-between mb-2 mt-3">
            <div class="col-lg-8 mt-5">
                <div class="d-flex tabs">
                    <div class="mr-4 tab">
                        <a [routerLink]="['/dashboard/artworks']" id="titles-card-artworks"
                            [class.active]="viewMode === 'artworks'" class="font-weight-light h1">Artworks</a>
                    </div>
                    <div class="tab">
                        <a [routerLink]="['/dashboard/collections']" id="titles-card-artworks"
                            [class.active]="viewMode === 'collections'" class="font-weight-light h1">Lists</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 mt-5 text-md-right" *ngIf="viewMode === 'collections'">
                <button type="button" (click)="openCreateList()"  class="btn btn-outline-primary py-2 cursor-pointer"
                    style="border: 2px solid;" data-toggle="modal" data-target="#exampleModalCenter">
                    <h5 class="font-weight-regular mb-0"> Create a List</h5>
                </button>
            </div>
        </div>
        <hr class="mb-1">
        <div class="row d-flex justify-content-center">
            <div class="col-md-12 col-12">
                <ng-container *ngIf="viewMode === 'artworks'">
                    <app-artworks-list *ngIf="items" [items]="items" [title]="false" [filters]="false" [endpoint]="'collector/artworks'"></app-artworks-list>
                </ng-container>
                <ng-container *ngIf="viewMode === 'collections'">
                    <app-artworks-track class="py-3"  [lists]="lists"></app-artworks-track>
                    <div *ngIf="lists.length == 0" class="text-center my-5">
                        <h1>Create your first List</h1>
                        <p class="font-weight-light">Use lists to organize or save artworks by client, room, etc. All lists are private unless you
                            share them.</p>
                        <button type="button" class="btn btn-primary" (click)="openCreateList()">Create List</button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</main>