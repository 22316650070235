<app-event-launch [event]="event"></app-event-launch>

<div class="container-fluid">
    <div class="row px-sm-4 mx-1">
        <div class="col-lg-12 px-sm-4 mt-5" id="event">
            <h2 class="text-secondary font-weight-bold">Available Artworks</h2>
        </div>
    </div>
    <div class="row justify-content-between align-items-center px-sm-4 mx-1">
        <div class="col-lg-4 pr-sm-4 pl-1 tags-event">
            <div class="tags-event d-flex">
                <a (click)="activeArtworkStatusFilter = 'digital'" [class.active]="activeArtworkStatusFilter == 'digital'" class="nav-link text-grey p-3 font-weight-light" style="cursor: pointer;">Digital</a>
                <a (click)="activeArtworkStatusFilter = 'physical'" [class.active]="activeArtworkStatusFilter == 'physical'" class="nav-link text-grey p-3 font-weight-light" style="cursor: pointer;">Physical</a>
                <a (click)="activeArtworkStatusFilter = 'all'" [class.active]="activeArtworkStatusFilter == 'all'" class="nav-link text-grey p-3 font-weight-light" style="cursor: pointer;">All</a>
            </div>
        </div>
        <div class="col-lg-7 px-0">
            <div class="row justify-content-xl-end align-items-center ml-3">
                <div class="col-lg-8 col-md-7 d-flex align-items-center px-0 mt-2 mr-2">
                    <input [(ngModel)]="artworkSearchFilter" class="form-control bg-light input-search border-0 w-100" type="text" placeholder="Search" checked>
                    <button type="button" class="btn btn-primary btn-search d-flex justify-content-center align-items-center">Search</button>
                </div>
                <div class="col-3 d-xl-flex justify-content-end px-0 mt-2 mr-xl-5 pr-xl-4">
                    <app-order-by></app-order-by>
                </div>
            </div>
        </div>
    </div>
    <div class="row px-sm-4 mx-1">
        <div class="d-flex flex-wrap justify-content-left">
            <ng-container *ngFor="let artwork of artworks">
                <div class="box-artwork my-2" style="position: relative;" *ngIf="filterArtworks(artwork)">
                    <app-preview-artwork [showPlay]="true" [img]="artwork.img" [preview]="artwork.preview" [styleImage]="'border-radius: 9px !important;height: 228px;object-fit: cover;width: 100%;'" [styleBox]="'height: 228px; border-radius: 6px;'"></app-preview-artwork>

                    <p class="text-secondary font-weight-bold mt-1 mb-0">{{ artwork.title }}</p>
                    <p class="text-secondary font-weight-regular mb-0" style="margin-top: -7px;">{{ artwork.artist.full_name }}</p>
                    <p *ngIf="artwork.versions.length > 0" class="font-weight-light text-grey-light mb-0" style="margin-top: -7px;"> US$ {{ (artwork.versions[0].price/100).toFixed(2) }}</p>
                    <p *ngIf="artwork.versions.length == 0" class="font-weight-bolder text-grey-light mb-0" style="margin-top: -7px;"> Sold out</p>
                    <button type="button" *ngIf="artwork.versions.length > 0"  [routerLink]="['/event/'+slug +'/'+artwork.id]" class="btn btn-lg btn-primary font-weight-light py-1 px-3 mt-2" style="height: 31px;width: 88px;font-size: 15px;">View</button>
                    <button type="button" *ngIf="artwork.versions.length == 0"  [routerLink]="['/event/'+slug +'/'+artwork.id]" class="btn btn-lg btn-primary font-weight-light py-1 px-3 mt-2" style="height: 31px;width: 88px;font-size: 15px;">View</button>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="row d-flex justify-content-center aling-items-center">
        <div class="col-lg-12 text-center mt-5 mb-5" *ngIf="artworks.length > 25">
            <a class="btn btn-lg btn-outline-primary font-weight-regular loadMore">Load more</a>
        </div>
    </div>
    
</div>