<div class="footer pt-4 mt-1 mb-5 pb-5">
    <div class="container-fluid pb-5 mb-5">
        <div class="d-lg-flex align-items-start mt-4 pt-3">
            <div class="col-lg-5 d-sm-flex justify-content-lg-between alpha-network pl-1">
                <div>
                    <div class="pb-1">
                        <a [routerLink]="['/home']" class="d-flex">
                            <img src="/assets/icons/digital-alphaaio.png" alt="digital-alphaaio">
                            <img src="/assets/icons/name-alphaaio.png" class="pl-3" alt="name-alphaaio" style="object-fit: contain;">
                        </a>
                    </div>
                    <div>
                        <ul class="d-flex icons-network">
                            <li class="mr-3 pr-1"><a class="text-secondary cursor-pointer" href="https://www.instagram.com/alphaaio/" target="_blank"><img src="/assets/icons/icon-instagram.svg"></a></li>
                            <li class="mr-3 pr-1"><a class="text-secondary cursor-pointer" href="https://twitter.com/geracaoalpha" target="_blank"><img src="/assets/icons/icon-twitter.svg"></a></li>
                            <li class="mr-3 pr-1"><a class="text-secondary cursor-pointer" href="https://www.facebook.com/search/top?q=alpha%27a%20inc." target="_blank"><img src="/assets/icons/icon-facebook.svg"></a></li>
                            <li class="mr-3 pr-1"><a class="text-secondary cursor-pointer" href="https://www.youtube.com/channel/UC6NO1zr_NObuhfnYUeOfpGg" target="_blank"><img src="/assets/icons/icon-YT.svg"></a></li>
                        </ul>
                    </div>
                </div>
                <div class="ml-sm-5">
                    <h5 class="text-primary font-weight-bold">Alphaa Inc.</h5>
                    <div>
                        <p class="text-secondary font-weight-regular mb-0">157 Columbus Avenue</p>
                        <p class="text-secondary font-weight-regular mb-">New York, NY 10069</p>
                    </div>
                    <div class="mt-4">
                        <a class="text-secondary font-weight-regular cursor-pointer">
                            <p class="text-secondary font-weight-regular mb-1">contato@alphaainc.com</p>
                        </a>
                        <p class="text-secondary font-weight-regular">+1 646 7634523</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 offset-lg-1 px-0">
                <div class="d-flex alpha-footer px-sm-3">
                    <div class="col-4 px-0">
                        <h5 class="text-primary font-weight-bold mb-0">The Platform</h5>
                        <ul>
                            <li class="mt-2 pb-1"><a class="text-secondary font-weight-regular cursor-pointer" [routerLink]="['/home-creator']">Creators</a></li>
                            <!-- <li class="mt-2 pb-1"><a class="text-secondary font-weight-regular cursor-pointer">Institutions</a></li> -->
                            <li class="mt-2 pb-1"><a class="text-secondary font-weight-regular cursor-pointer" [routerLink]="['/home-collect']">Collectors</a></li>
                            <!-- <li class="mt-2 pb-1"><a class="text-secondary font-weight-regular cursor-pointer">Content</a></li> -->
                            <!-- <li class="mt-2 pb-1"><a class="text-secondary font-weight-regular cursor-pointer">Integrations</a></li> -->
                        </ul>
                    </div>
                    <div class="col-4 pr-0">
                        <h5 class="text-primary font-weight-bold mb-0">Learn</h5>
                        <ul>
                            <li class="mt-2 pb-1"><a class="text-secondary font-weight-regular cursor-pointer" [routerLink]="['/learn']">NFTs</a></li>
                            <li class="mt-2 pb-1"><a class="text-secondary cursor-pointer" [routerLink]="['/privacy-policy']">Privacy Policy</a></li>
                            <!-- <li class="mt-2 pb-1"><a class="text-secondary font-weight-regular cursor-pointer">Subject</a></li> -->
                            <!-- <li class="mt-2 pb-1"><a class="text-secondary font-weight-regular cursor-pointer">Subject</a></li> -->
                        </ul>
                    </div>
                    <div class="col-4 pr-0">
                        <h5 class="text-primary font-weight-bold mb-0">Company</h5>
                        <ul class="mb-0">
                            <li class="mt-2 pb-1"><a class="text-secondary cursor-pointer" [routerLink]="['/about']">About Us</a></li>
                            <!-- <li class="mt-2 pb-1"><a class="text-secondary cursor-pointer">Careers</a></li> -->
                            <!-- <li class="mt-2 pb-1"><a class="text-secondary cursor-pointer">Team</a></li> -->
                            <!-- <li class="mt-2 pb-1"><a class="text-secondary cursor-pointer">Contact Us</a></li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-10 pl-lg-3 ml-1 mt-1">
                <p class="text-secondary small">© 2022 Alphaa Inc. All rights reserved.</p>
            </div>
        </div>
    </div>
</div>