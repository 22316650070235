import { Component, Input, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-artworks-card',
  templateUrl: './artworks-card.component.html',
  styleUrls: ['./artworks-card.component.scss']
})
export class ArtworksCardComponent implements OnInit {
  
  @Input() public artwork: any;
  @Input() public version: any;
  @Input() public link: any;
  @Input() public title: boolean;
  public classStyle: string;
  
  constructor(
    public helper: HelperService
    ) { }
    
    async ngOnInit() {
      this.setClass(this.artwork.width, this.artwork.height)
    }
    
    setClass(width: number, height: number){
      if(height == width){
        this.classStyle = 'square';
      }else if(height > width){
        this.classStyle = 'vertical';
      }else if(width > height){
        this.classStyle = 'horizontal';
      }
    }
  }
  