    <div class="" *ngIf="InstitutionAddStep === 0">
        <div class="modal-header justify-content-center">
            <h2 *ngIf="!invite" class="text-secondary font-weight-bold mt-2">Add Artist</h2>
            <h2 *ngIf="invite" class="text-secondary font-weight-bold mt-2">Edit Artist</h2>
            <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0 p-2">
                <img src="/assets/icons/close.svg">
            </button>
        </div>
        
        <div class="container modal-body" *ngFor="let artist of artists; let i = index">
            <div class="row px-4 mt-3">
                <div class="col-lg-12">
                    <div *ngIf="i > 0" class="d-flex justify-content-end align-item-center mb-3">
                        <a class="nav-link cursor-pointer d-flex align-item-center px-1" (click)="removeArtist(i)"> <img class="mr-1" src="/assets/icons/delete.svg"> <p class="font-weight-light text-grey-light mb-0">Delete</p></a>
                    </div>
                    <input class="form-control bg-light border-0 col py-4" style="height: 50px;" *ngIf="!invite" [(ngModel)]="artists[i].name" placeholder="Artist Name" type="text" required checked>
                    <input class="form-control bg-light border-0 col py-4" style="height: 50px;" *ngIf="invite" [disabled]="true" [(ngModel)]="invite.creator.full_name" placeholder="Artist Name" type="text" required checked>
                </div>
                <div class="col-lg-12 mt-3">
                    <input class="form-control bg-light border-0 col py-4" style="height: 50px;" *ngIf="!invite" [(ngModel)]="artists[i].email" placeholder="Artist email" type="text" required checked>
                    <input class="form-control bg-light border-0 col py-4" style="height: 50px;" *ngIf="invite" [disabled]="true" [(ngModel)]="invite.creator.email" placeholder="Artist email" type="text" required checked>
                </div>
            </div>
            <div class="row px-4 mt-4 pt-2">
                <div class="col-lg-12">
                    <div class="d-flex justify-content-between">
                        <p class="text-grey-light font-weight-light mb-0 Institution-p" (mouseout)="infoRoyalty=false">
                            Institution royalty 
                            <a class="cursor-pointer" (mouseover)="infoRoyalty=true">
                                <img src="/assets/icons/transaction.png">
                            </a>
                            <span class="infoRaoyalty" *ngIf="infoRoyalty">This information refers to the resale fee</span>
                        </p>
                        <p *ngIf="!invite" class="font-weight-bold text-primary mb-0">{{ artists[i].institution_fee_amount ? artists[i].institution_fee_amount : 5 }} %</p>
                        <p *ngIf="invite" class="font-weight-bold text-primary mb-0">{{ invite.institution_fee_amount ? invite.institution_fee_amount : 5  }} %</p>
                    </div>
                    <div class="w-100">
                        <input *ngIf="!invite" [(ngModel)]="artists[i].institution_fee_amount" type="range" min="0" max="10" [value]="" step="1" class="form-range w-100">
                        <input *ngIf="invite" [(ngModel)]="invite.institution_fee_amount" type="range" min="0" max="10" [value]="" step="1" class="form-range w-100">
                    </div>
                    <div class="d-flex justify-content-between text-porcent">
                        <p class="font-weight-light text-grey-light institution-number mb-0">00</p>
                        <p class="font-weight-light text-grey-light institution-number mb-0">10%</p>
                    </div>
                </div>
            </div>
            <div class="row px-4 mt-2">
                <div class="col-lg-12">
                    <div class="d-flex justify-content-between">
                        <p class="text-grey-light font-weight-light mb-0 Institution-p" (mouseout)="infoEscrow=false">
                            Institution escrow 
                            <a class="cursor-pointer" (mouseover)="infoEscrow=true">
                                <img src="/assets/icons/transaction.png">
                            </a>
                            <span class="infoEscrow" *ngIf="infoEscrow">This information refers to the first sale</span>
                        </p>
                        <p  *ngIf="!invite" class="font-weight-bold text-primary mb-0">{{ artists[i].institution_escrow_amount ? artists[i].institution_escrow_amount : 10  }} %</p>
                        <p *ngIf="invite" class="font-weight-bold text-primary mb-0">{{ invite.institution_escrow_amount ? invite.institution_escrow_amount : 10  }} %</p>
                    </div>
                    <div class="w-100">
                        <input *ngIf="!invite" [(ngModel)]="artists[i].institution_escrow_amount" min="0" max="100" type="range" [value]="" step="1" class="form-range w-100">
                        <input *ngIf="invite" [(ngModel)]="invite.institution_escrow_amount" min="0" max="100"  type="range" [value]="" step="1" class="form-range w-100">
                    </div>
                    <div class="d-flex justify-content-between text-porcent">
                        <p class="font-weight-light text-grey-light institution-number mb-0">00</p>
                        <p class="font-weight-light text-grey-light institution-number mb-0">100%</p>
                    </div>
                </div>
            </div>
            <hr>
        </div>
        
        
        <div *ngIf="!invite" class="row d-flex justify-content-center mt-4">
            <a (click)="addAnotherArtist()" class="cursor-pointer"><img src="/assets/icons/button-add.png" class="w-100"></a>
            <p (click)="addAnotherArtist()" class="cursor-pointer text-grey-light font-weight-light Institution-p mb-0 ml-2">Add another artist</p>
        </div>
        <div class="container mt-4">
            <div class="row modal-footer d-flex justify-content-center">
                <div class="col-lg-10">
                    <button  type="submit" *ngIf="!invite" class="w-100 btn btn-lg btn-primary font-weight-regular btn-invite" (click)="sendInvite()">Invite</button>
                    <button  type="submit" *ngIf="invite" class="w-100 btn btn-lg btn-primary font-weight-regular btn-invite" (click)="updateInvite()">Update</button>
                </div>
            </div>
        </div>
    </div>
    
    <div class="col" *ngIf="InstitutionAddStep === 1">
        <div class="modal-header justify-content-center">
            <h2 class="text-secondary font-weight-bold mt-2">Artist invited</h2>
            <button type="button" (click)="buttonClose()" aria-label="Close" class="btn btn-outline position-absolute right-0 top-0 p-1">
                <img src="/assets/icons/close.svg">
            </button>
        </div>
        <div class="container modal-body">
            <div class="row px-5 mt-3">
                <p class="text-grey-light font-weight-light invited-p px-5">
                    To be added to you institution, the artist/s you
                    invited will need to accept your invitation.
                </p>
            </div>
            <div class="container mt-4">
                <div class="row modal-footer d-flex justify-content-center">
                    <div class="col-lg-10">
                        <button  type="submit" class="w-100 btn btn-lg btn-primary font-weight-regular btn-invite" (click)="buttonClose()">Done</button>
                    </div>
                </div>
            </div>
        </div>
    </div>