import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-edit-folder',
  templateUrl: './modal-edit-folder.component.html',
  styleUrls: ['./modal-edit-folder.component.scss']
})
export class ModalEditFolderComponent implements OnInit {
  public formEditFolder: FormGroup;
  @Input() public folder;
  public id: number;




  constructor(
    public router: Router,
    public api: ApiService,
    public route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public modalService: NgbModal

  ) { }

  ngOnInit(): void {
    this.editFormFolder()
  }

  async editFolder({ valid, value }: { valid: boolean; value: any }) {
    if (valid) {

      let payload = {
        title: value.title,
        description: value.description
      }

      this.api.put('artworks/folders/' + this.folder.id, payload).then((response) => {
        this.modalService.dismissAll();
        location.reload()
      })
    }
  }

  private editFormFolder() {
    this.formEditFolder = this.formBuilder.group({
      title: [this.folder.title, Validators.required],
      description: [this.folder.description, Validators.required]
    })
  }
  acceptDeleteFolder(folder_id: number): void {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d2ae4d',
      cancelButtonColor: '#c00303',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.api.delete('artworks/folders/' + folder_id).then((response) => {
          Swal.fire(
            'Deleted!',
            'Your folder has been deleted.',
            'success',
          )
            .then((response) => {
              if (response.isConfirmed) {
                window.location.href = "/folders"
              }
            })
        })
      }
    })
  }

  buttonClose(){
    const modalRef = this.modalService.dismissAll();
   }

}
