<div class="container-fluid">
    <div class="row ml-2 mr-2">
        <div class="col mt-4">
            <p class="mb-1 text-grey">Your Vault / Collection name</p>
            <h1 class="text-primary font-weight-bold mb-1">{{ artwork?.title }}</h1>
            <p class="text-grey mb-5">{{ artwork?.artist.full_name }}</p>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row ml-md-2 mr-md-2 pb-5">
        <div class="col-md-4 col-lg-3 mb-5">
            <app-versionSpecs [artwork]="artwork"></app-versionSpecs>
        </div>
        <div class="col-md-8 col-lg-9">
            <div class="card">
                <div class="row card-header bg-transparent d-flex justify-content-between">
                    <div class="mt-2 mb-2 d-flex titles-version">
                        <a [routerLink]="['/artworks/'+artwork?.id+'/value']" routerLinkActive="selected" id="titles-card-versions" class="text-grey font-weight-light h4 card-title nav-link">Value</a>
                        <a [routerLink]="['/artworks/'+artwork?.id+'/record']" routerLinkActive="selected" id="titles-card-versions" class="text-grey font-weight-light h4 card-title nav-link pl-1">Record</a>
                        <a [routerLink]="['/artworks/'+artwork?.id+'/about']" routerLinkActive="selected" id="titles-card-artworks" class="text-grey font-weight-light h4 card-title nav-link pl-1">About</a>
                        <div *ngIf="artwork?.artist_id !== auth.userData.id && artwork?.version != 'sold_out'" class="d-flex text-right justify-content-end align-items-center">
                            <button (click)="buy('?tradeQr='+artwork.version.fake_token)"  class="btn btn-primary ml-2 px-4 cursor-pointer">Buy</button>
                        </div>
                    </div>

                    <div *ngIf="auth.userData.id && (artwork?.artist_id === auth.userData.id || institutionPermission) && artwork.version !== 'sold_out'" class="d-flex mt-2 text-right justify-content-end align-items-center mr-3">
                        <qrcode *ngIf="artwork?.version" [qrdata]="'https://www.alphaa.io/?tradeQr='+artwork?.version.fake_token" [width]="70" [errorCorrectionLevel]="'M'" [elementType]="'svg'"></qrcode>
                        <button (click)="openTransaction()"  class="btn btn-primary cursor-pointer ml-2" style="width: 120px; border-radius: 9px !important;">Trade</button>
                    </div>
                </div>
                <div class="card-body" *ngIf="viewMode === 'value'">
                    <div>
                        <h5 *ngIf="artwork?.version == 'sold_out'" class="font-weight-bold text-secondary">Original Value</h5>
                        <h5 *ngIf="artwork?.version != 'sold_out'" class="font-weight-bold text-secondary">Current Value</h5>
                        <h1 *ngIf="artwork?.version != 'sold_out'" class="text-primary font-weight-normal"> {{ helper.amountCurrencyFormat(artwork?.version.price) }}</h1>
                        <h1 *ngIf="artwork?.version == 'sold_out'" class="text-primary font-weight-normal"> {{ helper.amountCurrencyFormat(artwork?.original_price) }}</h1>
                    </div>
                    <div class="mt-5">
                        <!-- <h4 class="font-weight-bold text-secondary">Fluctuation</h4>
                        <highcharts-chart *ngIf="chartOptions.chart" [Highcharts]="Highcharts" [options]="chartOptions" style="width: 100%; height: 300px; display: block;"></highcharts-chart> -->
                    </div>
                </div>
                <div class="card-body" *ngIf="viewMode === 'record'">
                    <div class="row">
                        <div class="col-12">
                            <h4 class="text-secondary font-weight-bold">Details</h4>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-lg-3 pr-md-0">
                                    <p class="text-secondary font-weight-bold mb-2">Privacy setting</p>
                                </div>
                                <div class="col-lg-8 px-xl-0">
                                    <p class="text-secondary mb-2">Private</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-lg-3 pr-md-0">
                                    <p class="text-secondary font-weight-bold mb-2">Artist</p>
                                </div>
                                <div class="col-lg-8 px-xl-0">
                                    <p class="text-secondary mb-2">{{ artwork.artist.full_name }}</p>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="artwork.version != 'sold_out'" class="col-lg-12">
                            <div class="row">
                                <div class="col-lg-3 pr-md-0">
                                    <p class="text-secondary font-weight-bold mb-2">Hash Token</p>
                                </div>
                                <div class="col-lg-8 px-xl-0">
                                    <p *ngIf="artwork.version" class="text-secondary mb-2">{{ artwork.version.fake_token }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="row mt-3">
                        <div class="col-lg-12 mt-4">
                            <h5 class="text-secondary">Recent</h5>
                            <p class="text-grey"> Lorem ipsun dolor sit amet Lorem ipsin dolor sit amet.</p>
                        </div>
                    </div> -->
                    <!-- <hr>
                    <div class="row">
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-3 mt-2">
                                    <img src="/assets/img/purchased-green.png" style="width: 4em;" alt="update">
                                </div>
                                <div class="col-9">
                                    <h5 class="mb-0 mt-3 text-secondary">Purchased by you <span class="font-weight-bold">{{ artwork.owner.full_name }}</span></h5>
                                    <p class="text-grey">{{ updated_at }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <button type="button" (click)="openYourSmartContract(version)" class="border-0 rounded p-1 text-grey">
                                <img src="/assets/icons/transaction.png">
                                Smart Contract
                            </button>
                        </div>
                    </div> -->
                    <hr>
                    <div class="row mt-3">
                        <div class="col-lg-12 mt-4">
                            <h5 class="text-secondary font-weight-bold">History</h5>
                            <p class="text-grey"> Through alphaa.io's digital NFT certificates the full artwork history and transaction log can be easily viewed</p>
                        </div>
                    </div>
                    <div *ngFor="let history of histories">
                        <hr>
                        <div class="row">
                            <div class="col-md-8">
                                <div class="row mt-2">
                                    <div class="col-md-3 col-lg-1 mt-2">
                                        <img src="assets/img/purchased.png" alt="purchased">
                                    </div>
                                    <div class="col-md-9">
                                        <h5 class="mb-0 text-secondary">{{ textStatus(history.status) }} by <span class="font-weight-bold">{{ textSubject(history) }}</span></h5>
                                        <p class="text-grey">{{ helper.formatDate(history.created_at) }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 d-flex justify-content-md-end">
                                <button type="button" (click)="openTransactionTwo(history)"  class="btn pl-0 text-grey cursor-pointer"><img src="/assets/icons/transaction.png"> Transaction Data</button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <hr>
                        <div class="row">
                            <div class="col-md-8">
                                <div class="row mt-2">
                                    <div class="col-md-3 col-lg-1 mt-2">
                                        <img src="assets/img/create.png" alt="create">
                                    </div>
                                    <div class="col-md-9">
                                        <h5 class="mb-0 text-secondary">Created by <span class="font-weight-bold">{{ artwork.artist.full_name }}</span></h5>
                                        <p class="text-grey">{{ helper.formatDate(artwork.created_at) }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 d-flex justify-content-md-end">
                                <button type="button" (click)="openTransactionTwo(artwork)"  class="btn pl-0 text-grey cursor-pointer"><img src="/assets/icons/transaction.png"> Transaction Data</button>
                            </div>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="card-body" *ngIf="viewMode === 'about'">
                    <div class="row">
                        <div class="col-lg-12">
                            <h4 class="text-secondary font-weight-bold">About This Artwork</h4>
                            <div class="row">
                                <div class="col-9 mt-2">
                                    <b>More about this artwork</b>
                                    <p [innerHTML]="artwork.about" class="text-grey"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>