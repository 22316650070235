<div class="titles-card-dashboard d-flex justify-content-between align-items-center mb-2">
    <div class="all-titles d-flex align-item-center">
        <a class="h2 text-grey font-weight-light" [routerLink]="['/dashboard-institution/overview']" [class.disbaled]="disabledChart" routerLinkActive="selected" (click)="setOverviewMode('artworks')">Overview</a>
        <a class="h2 text-grey font-weight-light" [routerLink]="['/dashboard-institution/artists']" [class.disbaled]="disabledChart" routerLinkActive="selected" (click)="setOverviewMode('artists')">Artists</a>
        <a class="h2 text-grey font-weight-light" [routerLink]="['/dashboard-institution/artworks']" [class.disbaled]="disabledChart" routerLinkActive="selected" (click)="setOverviewMode('artworks')">Artworks</a>
        <a class="h2 text-grey font-weight-light" [routerLink]="['/dashboard-institution/folders']" [class.disbaled]="disabledChart" routerLinkActive="selected" (click)="setOverviewMode('artworks')">Folders</a>
        <a class="h2 text-grey font-weight-light" [routerLink]="['/dashboard-institution/trades']" [class.disbaled]="disabledChart" routerLinkActive="selected" (click)="setOverviewMode('artworks')">Trades</a>
        <a class="h2 text-grey font-weight-light" [routerLink]="['/dashboard-institution/profile']" [class.disbaled]="disabledChart" routerLinkActive="selected" (click)="setOverviewMode('artworks')">Profile</a>
    </div>
    <div class="buttons">
        <a class="text-grey font-weight-light share-profile cursor-pointer mr-3" [class.disbaled]="disabledChart" routerLinkActive="selected" (click)="shareProfile()">Share Profile</a>
        <a class="btn btn-secondary btn-create text-white px-4 py-2 my-1" [routerLink]="['/dashboard-institution/artworks-create']" *ngIf="viewMode == 'overview' || viewMode == 'artworks'">Create an Artwork</a>
        <a class="btn btn-secondary btn-create text-white py-2 my-1" style="width: 190px;" (click)="openAddArtist()" *ngIf="viewMode == 'artists'">Add an Artist</a>
    </div>
</div>
<div *ngIf="!auth.userData.stripe_account_complete">
    <app-create-account-stripe></app-create-account-stripe>
</div>