import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalInstitutionEditArtworkComponent } from 'src/app/modals/modal-institution-edit-artwork/modal-institution-edit-artwork.component';
import { ModalInstitutionArtworkRequestManagementComponent } from 'src/app/modals/modal-institution-artwork-request-management/modal-institution-artwork-request-management.component';
import { ModalInstitutionArtworkAddToFolderComponent } from 'src/app/modals/modal-institution-artwork-add-to-folder/modal-institution-artwork-add-to-folder.component';
import { ModalInstitutionArtworkChangePriceComponent } from 'src/app/modals/modal-institution-artwork-change-price/modal-institution-artwork-change-price.component';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-dropdown-institution-artworks',
  templateUrl: './dropdown-institution-artworks.component.html',
  styleUrls: ['./dropdown-institution-artworks.component.scss']
})
export class DropdownInstitutionArtworksComponent implements OnInit {

  @Input() artwork: any;

  constructor(
    private route: ActivatedRoute,
    public auth: AuthService,
    public router: Router,
    public modalService: NgbModal,
  ) {}

  ngOnInit(): void {
  }


  EditArtwork() {
    const modalRef = this.modalService.open(ModalInstitutionEditArtworkComponent, {
      windowClass: 'myCustomModalClass'
    });
    modalRef.componentInstance.artwork = this.artwork
  }


  RequestManagement() {
    const modalRef = this.modalService.open(ModalInstitutionArtworkRequestManagementComponent, {
      windowClass: 'myCustomModalClass'
    });
    modalRef.componentInstance.artwork = this.artwork

  }


  AddFolder() {
    const modalRef = this.modalService.open(ModalInstitutionArtworkAddToFolderComponent, {
      windowClass: 'myCustomModalClass'
    });
    modalRef.componentInstance.artwork = this.artwork
  }
 
 
 
  ChangePrice() {
    const modalRef = this.modalService.open(ModalInstitutionArtworkChangePriceComponent, {
      windowClass: 'myCustomModalClass'
    });
    modalRef.componentInstance.artwork = this.artwork
  }
}
