import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { HighchartsChartModule } from 'highcharts-angular';


import { AppComponent } from './app.component';
import { AuthComponent } from './pages/auth/auth.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { ArtworkComponent } from './pages/artwork/artwork.component';
import { Artwork2Component } from './pages/artwork2/artwork2.component';
import { Artwork3Component } from './pages/artwork3/artwork3.component';
import { ListsComponent } from './pages/lists/lists.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalTradeDigitalComponent } from './modals/modal-trade-digital/modal-trade-digital.component';
import { ModalTransactionComponent } from './modals/modal-transaction/modal-transaction.component';
import { ModalCollectionComponent } from './modals/modal-collection/modal-collection.component';
import { ModalEditCollectionComponent } from './modals/modal-edit-collection/modal-edit-collection.component';
import { VersionSpecsComponent } from './components/versionSpecs/versionSpecs.component';
// import { HeaderLoginComponent } from './components/header-login/header-login.component';
import { HeaderComponent } from './components/header/header.component';
import { ModalAuthComponent } from './modals/modal-auth/modal-auth.component';
import { ModalEditListComponent } from './modals/modal-edit-list/modal-edit-list.component';
import { ModalCreateListComponent } from './modals/modal-create-list/modal-create-list.component';
import { HomeCollectComponent } from './pages/home-collect/home-collect.component';
import { HomeCreatorComponent } from './pages/home-creator/home-creator.component';
import { ArtworksListComponent } from './components/artworks-list/artworks-list.component';
import { AuthFormsComponent } from './components/auth-forms/auth-forms.component';
import { YourArtComponent } from './pages/your-art/your-art.component';
import { ModalAddArtworkComponent } from './modals/modal-add-artwork/modal-add-artwork.component';
import { ArtworksListsBoxComponent } from './components/artworks-lists-box/artworks-lists-box.component';
import { SubscribeComponent } from './pages/subscribe/subscribe.component';
import { ModalAdressConfirmedComponent } from './modals/modal-adress-confirmed/modal-adress-confirmed.component';
import { ModalImportArtworksComponent } from './modals/modal-import-artworks/modal-import-artworks.component';
import { NgLocaleLocalization, NgLocalization } from '@angular/common';
import { ArtworksTrackComponent } from './components/artworks-track/artworks-track.component';
import { DropdownToArtworksComponent } from './components/dropdown-to-artworks/dropdown-to-artworks.component';
import { DashboardCreatorComponent } from './pages/dashboard-creator/dashboard-creator.component';
import { DashCreateComponent } from './components/dash-create/dash-create.component';
import { ModalCreateArtworkComponent } from './modals/modal-create-artwork/modal-create-artwork.component';
import { YourSmartContractComponent } from './modals/your-smart-contract/your-smart-contract.component';
import { VersionComponent } from './pages/version/version.component';
import { ApiService } from './services/api.service';
import { AuthService } from './services/auth.service';
import { DropdownOptionsRemoveTradeComponent } from './components/dropdown-options-remove-trade/dropdown-options-remove-trade.component';
import { ModalAddToListComponent } from './modals/modal-add-to-list/modal-add-to-list.component';
import { ModalAddToFolderComponent } from './modals/modal-add-to-folder/modal-add-to-folder.component';
import { CreateDropdownOptionsArtworksComponent } from './components/create-dropdown-options-artworks/create-dropdown-options-artworks.component';
import { OrderByComponent } from './components/order-by/order-by.component';
import { FoldersComponent } from './pages/folders/folders.component';
import { ModalEditFolderComponent } from './modals/modal-edit-folder/modal-edit-folder.component';
import { ModalCreateFoldersComponent } from './modals/modal-create-folders/modal-create-folders.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { TradesComponent } from './pages/trades/trades.component';
import { ModalTradeBuyerComponent } from './modals/modal-trade-buyer/modal-trade-buyer.component';
import { ModalTradeBuyerQrComponent } from './modals/modal-trade-buyer-qr/modal-trade-buyer-qr.component';
import { TradeListsComponent } from './components/trade-lists/trade-lists.component';
import { TradeItemComponent } from './components/trade-item/trade-item.component';
import { ModalRequestManegeComponent } from './modals/modal-request-manege/modal-request-manege.component';
import { ModalRequestManegentArtworkComponent } from './modals/modal-request-manegement-artwork/modal-request-manegement-artwork.component';

import { QRCodeModule } from 'angularx-qrcode';
import { LastTradesComponent } from './components/last-trades/last-trades.component';
import { AddTrackModalComponent } from './modals/add-track-modal/add-track-modal.component';
import { ModalTradeRatingComponent } from './modals/modal-trade-rating/modal-trade-rating.component';
import { TradeStatusComponent } from './components/trade-status/trade-status.component';
import { TradeStatusProgressComponent } from './components/trade-status-progress/trade-status-progress.component';
import { LearnComponent } from './pages/learn/learn.component';
import { VideoTrackComponent } from './components/video-track/video-track.component';
import { ModalVideoTrackComponent } from './modals/modal-video-track/modal-video-track.component';
import { ModalInstitutionRoyaltiesComponent } from './modals/modal-institution-royalties/modal-institution-royalties.component';
import { ModalArtistRoyaltiesComponent } from './modals/modal-artist-royalties/modal-artist-royalties.component';
import { ModalTradeDisputeComponent } from './modals/modal-trade-dispute/modal-trade-dispute.component';
import { ModalInstitutionAddArtistsComponent } from './modals/modal-institution-add-artists/modal-institution-add-artists.component';
import { ModalInstitutionRemoveComponent } from './modals/modal-institution-remove/modal-institution-remove.component';
import { ModalInviteInstitutionComponent } from './modals/modal-invite-institution/modal-invite-institution.component';
import { DashboardInstitutionComponent } from './pages/dashboard-institution/dashboard-institution.component';
import { DashInstitutionComponent } from './components/dash-institution/dash-institution.component';
import { TradesProgressInstitutionComponent } from './components/trades-progress-institution/trades-progress-institution.component';
import { TopSellingArtistsComponent } from './components/top-selling-artists/top-selling-artists.component';
import { OrderByInstitutionComponent } from './components/order-by-institution/order-by-institution.component';
import { InstitutionFromArtistsComponent } from './components/institution-from-artists/institution-from-artists.component';
import { DropdownInstitutionArtistsComponent } from './components/dropdown-institution-artists/dropdown-institution-artists.component';
import { DropdownInstitutionArtworksComponent } from './components/dropdown-institution-artworks/dropdown-institution-artworks.component';
import { InstitutionFromArtworksComponent } from './components/institution-from-artworks/institution-from-artworks.component';
import { ModalInstitutionEditArtworkComponent } from './modals/modal-institution-edit-artwork/modal-institution-edit-artwork.component';
import { ModalInstitutionRequestManagementComponent } from './modals/modal-institution-request-management/modal-institution-request-management.component';
import { ModalInstitutionArtworkRequestManagementComponent } from './modals/modal-institution-artwork-request-management/modal-institution-artwork-request-management.component';
import { ModalInstitutionArtworkAddToFolderComponent } from './modals/modal-institution-artwork-add-to-folder/modal-institution-artwork-add-to-folder.component';
import { ModalInstitutionArtworkChangePriceComponent } from './modals/modal-institution-artwork-change-price/modal-institution-artwork-change-price.component';
import { ArtworksTrackInstitutionComponent } from './components/artworks-track-institution/artworks-track-institution.component';
import { ModalVersionsArtworkToInstitutionComponent } from './modals/modal-versions-artwork-to-institution/modal-versions-artwork-to-institution.component';
import { ModalArtistRoyaltiesToInstitutionComponent } from './modals/modal-artist-royalties-to-institution/modal-artist-royalties-to-institution.component';
import { ModalInstitutionRoyaltiesToInstitutionComponent } from './modals/modal-institution-royalties-to-institution/modal-institution-royalties-to-institution.component';
import { ProfileInstitutionComponent } from './pages/profile-institution/profile-institution.component';
import { InstitutionDetailsArtistsComponent } from './components/institution-details-artists/institution-details-artists.component';
import { IconsRedesComponent } from './components/icons-redes/icons-redes.component';
import { InstitutionArtworksFromArtistComponent } from './components/institution-artworks-from-artist/institution-artworks-from-artist.component';
import { DropdownInstitutionArtworksFromArtistComponent } from './components/dropdown-institution-artworks-from-artist/dropdown-institution-artworks-from-artist.component';
import { ModalAddToShippingComponent } from './modals/modal-add-to-shipping/modal-add-to-shipping.component';

import { ButtonInstitutionProgressComponent } from './components/button-institution-progress/button-institution-progress.component';
import { ArtistDetailComponent } from './pages/artist-detail/artist-detail.component';
import { ModalPlatformFeeComponent } from './modals/modal-platform-fee/modal-platform-fee.component';
import { EventArtworkDetailsComponent } from './pages/event-artwork-details/event-artwork-details.component';
import { EventComponent } from './pages/event/event.component';
import { EventLaunchComponent } from './components/event-launch/event-launch.component';
import { EventSpecsComponent } from './components/event-specs/event-specs.component';
import { EventDataArtistsComponent } from './components/event-data-artists/event-data-artists.component';
import { EventTrackArtworksComponent } from './components/event-track-artworks/event-track-artworks.component';
import { EditProfileArtistComponent } from './components/edit-profile-artist/edit-profile-artist.component';
import { CallbackPipe } from './pipes/callback.pipe';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { ClipboardModule } from 'ngx-clipboard';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { FooterComponent } from './components/footer/footer.component';
import { CreateAccountStripeComponent } from './components/create-account-stripe/create-account-stripe.component';
import { ReturnStripeRegisterComponent } from './pages/return-stripe-register/return-stripe-register.component';
import { ProfileAvatarComponent } from './components/profile-avatar/profile-avatar.component';
import { ModalUploadImageComponent } from './modals/modal-upload-image/modal-upload-image.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProfileComponent } from './profile/profile.component';
import { SubscriptonPlansComponent } from './components/subscripton-plans/subscripton-plans.component';
import { PreviewArtworkComponent } from './components/preview-artwork/preview-artwork.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { TradeBoxComponent } from './components/trade-box/trade-box.component';
import { FoldersBoxComponent } from './components/folders-box/folders-box.component';
import { ArtistsDashboardInstitutionComponent } from './components/artists-dashboard-institution/artists-dashboard-institution.component';
import { LatestArtworkCreatedComponent } from './components/latest-artwork-created/latest-artwork-created.component';
import { CreateArtworksComponent } from './components/create-artworks/create-artworks.component';
import { InfoDashboardComponent } from './components/info-dashboard/info-dashboard.component';
import { ArtworksCardComponent } from './components/artworks-card/artworks-card.component';
import { ModalCreateAccountStripeComponent } from './modals/modal-create-account-stripe/modal-create-account-stripe.component';
import { ShareButtonComponent } from './components/share-button/share-button.component';


function initializeApp(
  apiService: ApiService,
  authService: AuthService
) {
  return () => {
    return new Promise((resolve) => {
      apiService.init().then(() => {
        authService.init();
        resolve(true);
      });
    })
  }
}

@NgModule({
  declarations: [
    CallbackPipe,
    AppComponent,
    AuthComponent,
    HomeComponent,
    VersionComponent,
    ArtworkComponent,
    Artwork2Component,
    Artwork3Component,
    ListsComponent,
    DashboardComponent,
    ReturnStripeRegisterComponent,
    ModalTradeDigitalComponent,
    ModalTransactionComponent,
    ModalCollectionComponent,
    ModalEditCollectionComponent,
    ModalCollectionComponent,
    ModalEditCollectionComponent,
    VersionSpecsComponent,
    HeaderComponent,
    ModalAuthComponent,
    ModalEditListComponent,
    ModalCreateListComponent,
    ModalRequestManegeComponent,
    ModalRequestManegentArtworkComponent,
    HomeCollectComponent,
    HomeCreatorComponent,
    ArtworksListComponent,
    ModalInviteInstitutionComponent,
    AuthFormsComponent,
    YourArtComponent,
    ModalAddArtworkComponent,
    ArtworksListsBoxComponent,
    SubscribeComponent,
    ModalImportArtworksComponent,
    VersionSpecsComponent,
    ArtworksTrackComponent,
    DropdownToArtworksComponent,
    DashboardCreatorComponent,
    DashCreateComponent,
    ModalCreateArtworkComponent,
    YourSmartContractComponent,
    DropdownOptionsRemoveTradeComponent,
    ModalAddToListComponent,
    ModalAddToFolderComponent,
    CreateDropdownOptionsArtworksComponent,
    OrderByComponent,
    FoldersComponent,
    ModalEditFolderComponent,
    ModalCreateFoldersComponent,
    TradesComponent,
    ModalTradeBuyerComponent,
    ModalTradeBuyerQrComponent,
    TradeItemComponent,
    TradeListsComponent,
    LastTradesComponent,
    AddTrackModalComponent,
    ModalTradeRatingComponent,
    ModalTradeDisputeComponent,
    TradeStatusComponent,
    TradeStatusProgressComponent,
    LearnComponent,
    VideoTrackComponent,
    ModalVideoTrackComponent,
    ModalInstitutionRoyaltiesComponent,
    ModalArtistRoyaltiesComponent,
    AboutComponent,
    ModalInstitutionAddArtistsComponent,
    ModalInstitutionRemoveComponent,
    DashboardInstitutionComponent,
    DashInstitutionComponent,
    TradesProgressInstitutionComponent,
    TopSellingArtistsComponent,
    OrderByInstitutionComponent,
    InstitutionFromArtistsComponent,
    DropdownInstitutionArtistsComponent,
    DropdownInstitutionArtworksComponent,
    InstitutionFromArtworksComponent,
    ModalInstitutionEditArtworkComponent,
    ModalInstitutionRequestManagementComponent,
    ModalInstitutionArtworkRequestManagementComponent,
    ModalInstitutionArtworkAddToFolderComponent,
    ModalInstitutionArtworkChangePriceComponent,
    ArtworksTrackInstitutionComponent,
    ModalVersionsArtworkToInstitutionComponent,
    ModalArtistRoyaltiesToInstitutionComponent,
    ModalInstitutionRoyaltiesToInstitutionComponent,
    ProfileInstitutionComponent,
    InstitutionDetailsArtistsComponent,
    IconsRedesComponent,
    InstitutionArtworksFromArtistComponent,
    DropdownInstitutionArtworksFromArtistComponent,
    ModalAddToShippingComponent,
    ButtonInstitutionProgressComponent,
    ArtistDetailComponent,
    ModalPlatformFeeComponent,
    EventArtworkDetailsComponent,
    EventComponent,
    EventLaunchComponent,
    EventSpecsComponent,
    EventDataArtistsComponent,
    EventTrackArtworksComponent,
    EditProfileArtistComponent,
    PrivacyPolicyComponent,
    FooterComponent,
    CreateAccountStripeComponent,
    ProfileAvatarComponent,
    ModalUploadImageComponent,
    ProfileComponent,
    SubscriptonPlansComponent,
    PreviewArtworkComponent,
    TradeBoxComponent,
    FoldersBoxComponent,
    ArtistsDashboardInstitutionComponent,
    LatestArtworkCreatedComponent,
    CreateArtworksComponent,
    InfoDashboardComponent,
    ArtworksCardComponent,
    ModalCreateAccountStripeComponent,
    ShareButtonComponent,
  ],
  imports: [
    CKEditorModule,
    CreditCardDirectivesModule,
    ClipboardModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HighchartsChartModule,
    AppRoutingModule,
    NgbModule,
    NgSelectModule,
    NgxFileDropModule,
    QRCodeModule,
    CurrencyMaskModule,
    NgxIntlTelInputModule,
    BrowserAnimationsModule,
    GoogleTagManagerModule.forRoot({
      id: "GTM-PX5X5PT",
    })
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [ApiService, AuthService], multi: true },
    { provide: NgLocalization, useClass: NgLocaleLocalization },
    {provide: 'googleTagManagerId',  useValue: "GTM-PX5X5PT"}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
