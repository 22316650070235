<app-event-launch [event]="event"></app-event-launch>
<div class="container-fluid p-5">
    <div class="row">
        <div class="col-12">
            <a [href]="'/event/'+slug" class="text-grey-light">< Back to gallery</a>
            <h1 class="text-secondary font-weight-bold">Artworks</h1>
        </div>
    </div>
    <div class="row art-details d-flex justify-content-between">
        <div class="col-md-5 col-lg-6 mt-3">
            <!-- <img class="artist-artwork" width="100%" [src]="artwork.img" alt="obra do artista"> -->
            <app-preview-artwork [preview]="artwork.preview" [img]="artwork.img" [styleImage]="'width: 100%;max-height:100% !important; height: auto !important'" [styleBox]="'width: 100%;max-height:100% !importante; height: auto !important'"></app-preview-artwork>

            <div class="mt-4 pt-3">
                <h5 class="text-secondary font-weight-bold">About this artwork</h5>
                <p [innerHTML]="artwork?.about" class="text-grey font-weight-light"></p>
            </div>
        </div>
        <div class="col-md-5 col-lg-5">
            <app-event-specs [artwork]="artwork" [event]="event"></app-event-specs>
        </div>
    </div>
    <app-event-data-artists [artwork]="artwork"></app-event-data-artists>
    <app-event-track-artworks [artwork]="artwork"></app-event-track-artworks>
</div>