<header *ngIf="auth.checkPermissions('signed-in')">
    <nav class="navbar navbar-expand-lg shadow" id="header-home" *ngIf="auth.profileView == 'creator' || auth.profileView == 'institution'">
        <div class="container-fluid px-sm-4">
            <div class="pl-sm-3">
                <a *ngIf="auth.profileView == 'creator'" [routerLink]="['dashboard-creator/overview']">
                    <img style="width: 150px;" src="../../../assets/logo.svg" alt="alpha">
                </a>
                <a *ngIf="auth.profileView == 'institution'" [routerLink]="['dashboard-institution/overview']">
                    <img style="width: 150px;" src="../../../assets/logo.svg" alt="alpha">
                </a>
            </div>
            <button type="button" class="navbar-toggler cursor-pointer" (click)="isMenuCollapsed = !isMenuCollapsed">
                &#9776;
            </button>
            <nav class="collapse navbar-collapse justify-content-end" [ngbCollapse]="isMenuCollapsed">
                <ul class="titles-card-header navbar-nav text-grey d-flex mr-2" type="none" (click)="isMenuCollapsed = true">
                    <li class="nav-item mr-2" *ngIf="auth.profileView == 'creator'">
                        <a [routerLink]="['dashboard-creator/overview']" class="nav-link font-weight-light text-grey-link px-2" routerLinkActive="selected">Dashboard</a>
                    </li>
                    <li class="nav-item mr-2" *ngIf="auth.profileView == 'institution'">
                        <a [routerLink]="['dashboard-institution/overview']" class="nav-link font-weight-light text-grey-link px-2" routerLinkActive="selected">Dashboard</a>
                    </li>
                </ul>

                <div class="nav-item nav-link mr-2" ngbDropdown display="dynamic" placement="bottom-right">
                    <a type="button" ngbDropdownToggle style="cursor: pointer !important;">
                        <img src="../assets/icons/perfil.svg" alt="">
                    </a>
                    <div ngbDropdownMenu="dropdownBasic" class="dropdown-menu mt-3" style="box-shadow: 2px 2px 10px 0px #dbdddd;">
                        <!-- <a ngbDropdownItem style="cursor: pointer" class="dropDownContent borderRadiusUpBox font-weight-light text-grey-link" (click)="auth.switchProfileView('creator')" *ngIf="auth.checkPermissions('switch-account') && auth.subscriptionActive.getValue()">Switch to Creator</a>
                        <a ngbDropdownItem style="cursor: pointer" class="dropDownContent borderRadiusUpBox font-weight-light text-grey-link" (click)="auth.switchProfileView('institution')" *ngIf="auth.checkPermissions('switch-account') && auth.institutionActive.getValue()">Switch to Institution</a> -->
                        <!-- <a ngbDropdownItem style="cursor: pointer" class="dropDownContent borderRadiusUpBox font-weight-light text-grey-link" href="/subscribe" *ngIf="auth.checkPermissions('switch-account') && auth.institutionActive.getValue() == false && auth.subscriptionActive.getValue() == false">Subscription Plans</a> -->
                        <a ngbDropdownItem style="cursor: pointer;" class="dropDownContent borderRadiusUpBox font-weight-light text-grey-link" (click)="auth.switchProfileView('collector')" *ngIf="auth.checkPermissions('switch-account') && (auth.isProfile('creator') || auth.isProfile('institution'))">Switch to Collector</a>
                        <!-- <a ngbDropdownItem style="cursor: pointer;" class="dropDownContent font-weight-light text-grey-link">Account</a> -->
                        <a ngbDropdownItem style="cursor: pointer" class="dropDownContent borderRadiusBox font-weight-light text-grey-link" href="/subscribe">Subscription Plans</a>
                        <!-- <a  ngbDropdownItem style="cursor: pointer" class="dropDownContent borderRadiusBox font-weight-light text-grey-link" href="/learn">Learn</a> -->
                        <a ngbDropdownItem style="cursor: pointer;" class="dropDownContent borderRadiusDownBox font-weight-light text-grey-link" (click)="auth.logout()" *ngIf="auth.checkPermissions('signed-in')">Log out</a>
                    </div>
                </div>
            </nav>
        </div>
    </nav>
    <nav class="navbar navbar-expand-lg shadow" id="header-creator" *ngIf="auth.profileView == 'collector'">
        <div class="container-fluid px-sm-4">
            <div class="pl-sm-3">
                <a [routerLink]="['dashboard/artworks']">
                    <img style="width: 150px;" src="../../../assets/logo.svg" alt="alpha">
                </a>
            </div>
            <button type="button" class="navbar-toggler cursor-pointer" (click)="isMenuCollapsed = !isMenuCollapsed">
                &#9776;
            </button>
            <nav class="collapse navbar-collapse justify-content-end" [ngbCollapse]="isMenuCollapsed">
                <ul class="titles-card-header navbar-nav text-grey d-flex mr-2" type="none" (click)="isMenuCollapsed = true">
                    <li class="nav-item mr-2">
                        <a [routerLink]="['dashboard/artworks']" class="nav-link font-weight-light text-grey-link px-2" routerLinkActive="selected">Dashboard</a>
                    </li>
                    <li class="nav-item mr-2">
                        <a [routerLink]="['purchased-works']" class="nav-link font-weight-light text-grey-link px-2" routerLinkActive="selected">Purchased Works</a>
                    </li>
                    <li class="nav-item mr-2">
                        <a [routerLink]="['/lists']" class="nav-link font-weight-light text-grey-link px-2" routerLinkActive="selected">Lists</a>
                    </li>
                    <li class="nav-item mr-2">
                        <a [routerLink]="['/trades']" class="nav-link font-weight-light text-grey-link px-2" routerLinkActive="selected">Trades</a>
                    </li>

                    <!-- <div class="d-flex ml-2 mt-1">
                        <li class="nav-item mr-4">
                            <a>
                                <img src="../assets/icons/lupa.svg" alt="">
                            </a>
                        </li>
                        <li class="nav-item mr-4">
                            <a>
                                <img src="../assets/icons/bolsa.svg" alt="">
                            </a>
                        </li>
                    </div> -->
                </ul>
                <div class="nav-item nav-link mr-2" ngbDropdown display="dynamic" placement="bottom-right">
                    <a id="dropdownBasic1" ngbDropdownToggle style="cursor: pointer !important;">
                        <img src="../assets/icons/perfil.svg" alt="">
                    </a>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" placement="bottom-right" class="dropdown-menu mt-3" style="box-shadow: 2px 2px 10px 0px #dbdddd;">
                        <a ngbDropdownItem style="cursor: pointer" class="dropDownContent borderRadiusUpBox font-weight-light text-grey-link" (click)="auth.switchProfileView('creator')" *ngIf="auth.checkPermissions('switch-account') && auth.subscriptionActive.getValue()">Switch to Creator</a>
                        <a ngbDropdownItem style="cursor: pointer" class="dropDownContent borderRadiusUpBox font-weight-light text-grey-link" (click)="auth.switchProfileView('institution')" *ngIf="auth.checkPermissions('switch-account') && auth.institutionActive.getValue()">Switch to Institution</a>
                        <!-- <a ngbDropdownItem style="cursor: pointer" class="dropDownContent borderRadiusUpBox font-weight-light text-grey-link" href="/subscribe" *ngIf="auth.checkPermissions('switch-account') && auth.institutionActive.getValue() == false && auth.subscriptionActive.getValue() == false">Subscription Plans</a> -->
                        <a ngbDropdownItem style="cursor: pointer" class="dropDownContent borderRadiusUpBox font-weight-light text-grey-link" href="/subscribe">Subscription Plans</a>
                        <a ngbDropdownItem style="cursor: pointer" class="dropDownContent borderRadiusBox font-weight-light text-grey-link" (click)="auth.switchProfileView('collector')" *ngIf="auth.checkPermissions('switch-account') && auth.isProfile('creator')">Switch to Collector</a>

                        <!-- <a ngbDropdownItem  class="dropDownContent text-grey cursor-pointer">Account</a> -->
                        <a ngbDropdownItem class="dropDownContent borderRadiusDownBox font-weight-light text-grey-link cursor-pointer" (click)="auth.logout()" *ngIf="auth.checkPermissions('signed-in')">Log out</a>
                    </div>
                </div>
            </nav>
        </div>
    </nav>
    <!-- <div class="d-sm-flex justify-content-center align-items-center text-center bg-primary w-100" style="height: 60px;">
        <p class="mb-0 text-white font-weight-light">Explore our free layout to show your own work!</p>
        <a class="nav-link cursor-pointer d-flex justify-content-center align-items-center px-2" *ngIf="router.url != '/event/neoren-alphaaio-co-exhibit'" [routerLink]="['/event/neoren-alphaaio-co-exhibit']">
            <p class="mb-0 text-white font-weight-bold">Check our gallery</p>
            <img class="ml-2 pl-1" src="/assets/icons/check-our-gallery.svg" alt="check-our-gallery">
        </a>
    </div> -->
</header>
<!-- header das telas de login -->
<!-- <header *ngIf="auth.isAuthenticated.getValue() == false">
    <nav class="navbar navbar-expand-lg navbar-light shadow" id="header-home">
        <div class="container-fluid">
            <div class="d-flex">
                <img class="icon-alpha" src="assets/img/icon-alpha.jpeg" alt="iconAlpha">
                <h4 class="ml-2 font-weight-bold text-secondary">alpha'a.io</h4>
            </div>
            <button type="button" class="navbar-toggler cursor-pointer"  (click)="isMenuCollapsed = false"
            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse ml-3" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto ">
                <li class="nav-item">
                    <a class="nav-link font-weight-regular text-grey-light" href="#">The vault</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link font-weight-regular text-grey-light" href="#">How it works</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link font-weight-regular text-grey-light" href="#">About Us</a>
                </li>
            </ul>
            <div>
                <ul type="none" class="d-flex align-items-center">
                    <li>
                        <a href="#" class="font-weight-bold text-primary mr-4 nav-link font-weight-regular text-grey-light">login</a>
                    </li>
                    <li><a href="#" class="font-weight-bold btn btn-primary text-light nav-link font-weight-regular text-grey-light btn-sign pl-4 pr-4">Sign Up</a></li>
                </ul>
            </div>
        </div>
    </div>
</nav>
</header> -->
<!-- Header das telas home collect e collector -->
<header class="titles-card-header" *ngIf="!auth.checkPermissions('signed-in')">
    <nav class="navbar navbar-expand-lg navbar-light shadow" id="header-home">
        <div class="container-fluid mx-sm-3">
            <button type="button" class="navbar-toggler mr-auto border-0 cursor-pointer" (click)="isMenuCollapsed = !isMenuCollapsed">
                &#9776;
            </button>

            <a class="logo mr-4 ml-sm-3" [routerLink]="['/auth/login']"><img style="width: 150px;" src="../../../assets/logo.svg" alt="alpha"></a>

            <a [routerLink]="['/auth/login']" class="d-block d-lg-none mobile-login"><img src="../assets/icons/perfil.svg" alt=""></a>

            <div [ngbCollapse]="isMenuCollapsed" class="d-lg-flex justify-content-lg-end collapse navbar-collapse" id="navbarSupportedContent" (click)="isMenuCollapsed = true">
                <!-- <ul class="titles-card-header navbar-nav mr-auto">
                    <li class="nav-item text-center text-lg-left">
                        <a class="nav-link font-weight-light text-grey-link mr-2" [routerLink]="['/home-creator']" routerLinkActive="selected">Creator</a>
                    </li>
                    <li class="nav-item text-center text-lg-left">
                        <a class="nav-link font-weight-light text-grey-link mr-2" [routerLink]="['/home-collect']" routerLinkActive="selected">Collector </a>
                    </li>
                    <li class="nav-item text-center text-lg-left">
                        <a class="nav-link font-weight-light text-grey-link mr-2" [routerLink]="['/learn']" routerLinkActive="selected">Learn</a>
                    </li>
                    <li class="nav-item text-center text-lg-left">
                        <a class="nav-link font-weight-light text-grey-link" [routerLink]="['/about']" routerLinkActive="selected">About Us</a>
                    </li>
                </ul> -->
                <div class="auth-buttons d-lg-flex d-block justify-content-end text-center text-lg-left">
                    <a [routerLink]="['/auth/login']" class="nav-link font-weight-light text-grey-link pr-2 mr-4 d-flex justify-content-center align-items-center" routerLinkActive="selected">Login</a>
                    <a [routerLink]="['/auth/register']" class="btn btn-primary font-weight-regular text-white btn-sign mr-2">Sign Up</a>
                </div>
            </div>
        </div>
    </nav>
    <!-- <div class="d-sm-flex justify-content-center align-items-center text-center bg-primary w-100 py-2">
        <p class="mb-0 text-white font-weight-light py-2">Explore our free layout to show your own work!</p>
        <a class="nav-link cursor-pointer d-flex justify-content-center align-items-center px-2 py-1" *ngIf="router.url != '/event/neoren-alphaaio-co-exhibit'" [routerLink]="['/event/neoren-alphaaio-co-exhibit']">
            <p class="mb-0 text-white font-weight-bold">Check our gallery</p>
            <img class="ml-2 pl-1" src="/assets/icons/check-our-gallery.svg" alt="check-our-gallery">
        </a>
    </div> -->
</header>