import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalUploadImageComponent } from 'src/app/modals/modal-upload-image/modal-upload-image.component';

@Component({
  selector: 'app-profile-avatar',
  templateUrl: './profile-avatar.component.html',
  styleUrls: ['./profile-avatar.component.scss']
})
export class ProfileAvatarComponent implements OnInit {
  changeText: boolean;
  
  constructor(
    public modalService: NgbModal,
    public api: ApiService,
    public auth: AuthService,
    ) { 
      this.changeText = false;
    }
    
    ngOnInit(): void {
    }
    
    uploadAvatar(){
      const modalRef = this.modalService.open(ModalUploadImageComponent);
      modalRef.componentInstance.typeUpload = 'avatar';
    }
  }
  