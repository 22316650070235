import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import {DomSanitizer} from '@angular/platform-browser';
@Injectable({
  providedIn: 'root'
})

export class HelperService {
  
  constructor(
    public api: ApiService,
    private sanitizer:DomSanitizer
    
    ) {
    }
    
    sanitize(url:string){
      return this.sanitizer.bypassSecurityTrustUrl(url);
    }
    
    amountCurrencyFormat(amount) {
      const price = amount/100
      let dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return dollarUS.format(price)
    }
    
    formatAddress(model) {
      if (!model || typeof (model) != 'object' || !model.address) return '';
      let address = model.address + (model.address_number ? ', ' + model.address_number : '') + (model.complement ? ' - ' + model.complement : '') + ' - ' + model.district + ' • ' + model.city + ' / ' + model.state;
      return address;
    }
    createArray(i) {
      return i ? Array.from(new Array(Math.ceil(i)), (val, index) => index + 1) : [];
    }
    transformDate(date) {
      return new Date(date);
    }
    
    formatDateTime(date) {
      if (typeof (date) === 'string') {
        date = this.transformDate(date);
      }
      var options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
      return date.toLocaleDateString("en-US", options);
    }
    
    formatDate(date) {
      if (typeof (date) === 'string') {
        date = this.transformDate(date);
      }
      var options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return date.toLocaleDateString("en-US", options);
    }
    
    getImageSrc(img, size: any = false) {
      if (environment.IMAGE_SIZES && environment.IMAGE_SIZES.length > 0) {
        if (size) {
          size = size + '/';
        } else {
          size = '240/';
        }
      } else {
        size = '';
      }
      if (!img) return '';
      if (img.indexOf('://') > 0) {
      return img;
    } else {
      return environment.URL_ASSETS + size + img;
    }
  }
  baseName(str) {
    if (str) {
      let base = new String(str).substring(str.lastIndexOf('/') + 1);
      if (base.lastIndexOf(".") != -1)
      base = base.substring(0, base.lastIndexOf("."));
      return base;
    }
    return;
  }
  
  imgSrc(img) {
    if (typeof (img) != 'undefined' && img != null) {
      if (img.indexOf("http") >= 0) {
        return img;
      }
      if (typeof (img) == 'string') {
        return environment.URL_ASSETS + img;
      }
    }
  }
  // setSceUrl(src) {
  //   return $sce.trustAsResourceUrl(src);
  // }
  
  // matchReplaceContent(str) {
  //   let found = [];
  //   let rxp = /{{([^}]+)}}/g;
  //   let curMatch;
  //   while (curMatch = rxp.exec(str)) {
  //     found.push(curMatch[1]);
  //   }
  
  //   return found;
  
  // }
  
  validaCEP(cep, obj, prefix, ev) {
    cep = this.maskCep(cep, ev);
    let cepNum = cep.replace(/[^0-9]+/g, '');
    if (cepNum.length == 8) {
      this.api.get('https://viacep.com.br/ws/' + (cepNum.toString()) + '/json/').then(function (response) {
      obj[prefix + 'address'] = response.data.logradouro;
      obj[prefix + 'district'] = response.data.bairro;
      obj[prefix + 'city'] = response.data.localidade;
      obj[prefix + 'state'] = response.data.uf;
    }).catch(function () { });
  }
  return cep;
}




/*--------------------------------------------------------
-----------		MASCARAS E VALIDADORES		--------------
--------------------------------------------------------*/
maskNumber(model, ev) {
  if (!model) return '';
  return model.replace(/[^0-9]+/g, '');
}
maskCep(model, ev) {
  if (!model) return '';
  let new_model = '';
  let numbers = model.replace(/[^0-9]+/g, '');
  if (numbers != '') {
    new_model = numbers.length > 5 ? numbers.substring(0, 5) + '-' + numbers.substring(5, 8) : numbers.substring(0, 5);
  }
  // if (ev) {
  //   if (numbers.length < 8) {
  //     $(ev.currentTarget).addClass('invalid')
  //   } else {
  //     $(ev.currentTarget).removeClass('invalid')
  //   }
  // }
  return new_model;
}
maskCpfCnpj(model, ev) {
  let numbers = model.replace(/[^0-9]+/g, '');
  if (numbers.length > 11) {
    return this.maskCnpj(model, ev);
  } else {
    return this.maskCpf(model, ev);
  }
}
maskCpf(model, ev) {
  if (!model) return '';
  let new_model = '';
  let numbers = model.replace(/[^0-9]+/g, '');
  if (numbers != '') {
    new_model = numbers.length > 3 ? numbers.substring(0, 3) + '.' + numbers.substring(3, 6) : numbers.substring(0, 3);
    new_model = numbers.length > 6 ? new_model.substring(0, 7) + '.' + numbers.substring(6, 9) : new_model;
    new_model = numbers.length > 9 ? new_model.substring(0, 11) + '-' + numbers.substring(9, 11) : new_model;
  }
  if (ev) {
    
    // if (this.validarDocumento(numbers)) {
    //   $(ev.currentTarget).removeClass('invalid')
    //   $(ev.currentTarget).removeClass('invalid-document')
    // } else {
    //   $(ev.currentTarget).addClass('invalid')
    //   $(ev.currentTarget).addClass('invalid-document')
    // }
    // if(numbers.length < 11){
    // 	$(ev.currentTarget).addClass('invalid')
    // }else{
    // 	$(ev.currentTarget).removeClass('invalid')
    // }
  }
  
  return new_model;
}
maskCnpj(model, ev) {
  if (!model) return '';
  let new_model = '';
  let numbers = model.replace(/[^0-9]+/g, '');
  if (numbers != '') {
    new_model = numbers.length > 2 ? numbers.substring(0, 2) + '.' + numbers.substring(2, 5) : numbers.substring(0, 2);
    new_model = numbers.length > 5 ? new_model.substring(0, 6) + '.' + numbers.substring(5, 8) : new_model;
    new_model = numbers.length > 8 ? new_model.substring(0, 10) + '/' + numbers.substring(8, 12) : new_model;
    new_model = numbers.length > 12 ? new_model.substring(0, 15) + '-' + numbers.substring(12, 14) : new_model;
  }
  if (ev) {
    // if (this.validarDocumento(numbers)) {
    //   $(ev.currentTarget).removeClass('invalid')
    //   $(ev.currentTarget).removeClass('invalid-document')
    // } else {
    //   $(ev.currentTarget).addClass('invalid')
    //   $(ev.currentTarget).addClass('invalid-document')
    // }
    // if(numbers.length < 14){
    // 	$(ev.currentTarget).addClass('invalid')
    // }else{
    // 	$(ev.currentTarget).removeClass('invalid')
    // }
  }
  
  return new_model;
}

maskTel(model, ev) {
  if (model && model != '') {
    let new_model = '';
    let numbers = model.replace(/[^0-9]+/g, '');
    if (numbers != '') {
      new_model = '(' + numbers.substring(0, 2);
      if (numbers.length < 11) {
        new_model = numbers.length > 2 ? new_model.substring(0, 3) + ') ' + numbers.substring(2, 7) : new_model;
        new_model = numbers.length > 6 ? new_model.substring(0, 9) + '-' + numbers.substring(6, 11) : new_model;
      } else {
        new_model = numbers.length > 2 ? new_model.substring(0, 3) + ') ' + numbers.substring(2, 7) : new_model;
        new_model = numbers.length > 6 ? new_model.substring(0, 10) + '-' + numbers.substring(7, 11) : new_model;
      }
    }
    // if (ev) {
    //   if (numbers.length < 10) {
    //     $(ev.currentTarget).addClass('invalid')
    //   } else {
    //     $(ev.currentTarget).removeClass('invalid')
    //   }
    // }
    return new_model
  }
  return;
}


maskDate(model, format, ev) {
  if (model && model != '') {
    if (model.indexOf('-') >= 0) {
      let tmpModel = model.split('-');
      model = ([tmpModel[2], tmpModel[1], tmpModel[0]]).join('/');
    }
    let new_model = '';
    let numbers = model.replace(/[^0-9]+/g, '');
    let len;
    if (numbers != '') {
      new_model = numbers.substring(0, 2);
      switch (format) {
        case 'dd/mm':
        len = 4;
        new_model = numbers.length > 2 ? new_model.substring(0, 2) + '/' + numbers.substring(2, 4) : new_model;
        // new_model = numbers.length > 5 ? new_model.substring(0,5) + '/'  + numbers.substring(2,4) : new_model;
        break;
        case 'mm/yyyy':
        len = 6;
        new_model = numbers.length > 2 ? new_model.substring(0, 2) + '/' + numbers.substring(2, 6) : new_model;
        // new_model = numbers.length > 5 ? new_model.substring(0,5) + '/'  + numbers.substring(2,4) : new_model;
        break;
        case 'dd/mm/yy':
        len = 6;
        new_model = numbers.length > 2 ? new_model.substring(0, 2) + '/' + numbers.substring(2, 4) : new_model;
        new_model = numbers.length > 4 ? new_model.substring(0, 5) + '/' + numbers.substring(4, 6) : new_model;
        break;
        case 'dd/mm/yyyy':
        len = 8;
        new_model = numbers.length > 2 ? new_model.substring(0, 2) + '/' + numbers.substring(2, 4) : new_model;
        new_model = numbers.length > 4 ? new_model.substring(0, 5) + '/' + numbers.substring(4, 8) : new_model;
        break;
      }
    }
    // if (ev) {
    //   if (numbers.length < len) {
    //     $(ev.currentTarget).addClass('invalid')
    //   } else {
    //     $(ev.currentTarget).removeClass('invalid')
    //   }
    // }
    return new_model
  }
  return;
}

validaEmail(model, ev) {
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // if (re.test(String(model).toLowerCase())) {
  //   $(ev.currentTarget).addClass('invalid');
  // } else {
  //   $(ev.currentTarget).removeClass('invalid');
  // }
  
}



validarDocumento(cpf_cnpj) {
  return this.valida_cpf_cnpj(cpf_cnpj);
}
verifica_cpf_cnpj(valor) {
  valor = valor.toString();
  valor = valor.replace(/[^0-9]/g, '');
  if (valor.length === 11) {
    return 'CPF';
  } else if (valor.length === 14) {
    return 'CNPJ';
  } else {
    return false;
  }
}
calc_digitos_posicoes(digitos, posicoes = 10, soma_digitos = 0) {
  digitos = digitos.toString();
  for (let i = 0; i < digitos.length; i++) {
    soma_digitos = soma_digitos + (digitos[i] * posicoes);
    posicoes--;
    if (posicoes < 2) {
      posicoes = 9;
    }
  }
  soma_digitos = soma_digitos % 11;
  if (soma_digitos < 2) {
    soma_digitos = 0;
  } else {
    soma_digitos = 11 - soma_digitos;
  }
  let cpf = digitos + soma_digitos;
  return cpf;
}
valida_cpf(valor) {
  valor = valor.toString();
  valor = valor.replace(/[^0-9]/g, '');
  let digitos = valor.substr(0, 9);
  let novo_cpf = this.calc_digitos_posicoes(digitos);
  novo_cpf = this.calc_digitos_posicoes(novo_cpf, 11);
  return novo_cpf === valor
  
}
valida_cnpj(valor) {
  valor = valor.toString();
  valor = valor.replace(/[^0-9]/g, '');
  
  let cnpj_original = valor;
  let primeiros_numeros_cnpj = valor.substr(0, 12);
  let primeiro_calculo = this.calc_digitos_posicoes(primeiros_numeros_cnpj, 5);
  let segundo_calculo = this.calc_digitos_posicoes(primeiro_calculo, 6);
  let cnpj = segundo_calculo;
  if (cnpj === cnpj_original) {
    return true;
  }
  return false;
}
valida_cpf_cnpj(valor) {
  let valida = this.verifica_cpf_cnpj(valor);
  valor = valor.toString();
  valor = valor.replace(/[^0-9]/g, '');
  if (valida === 'CPF') {
    return this.valida_cpf(valor);
  } else if (valida === 'CNPJ') {
    return this.valida_cnpj(valor);
  } else {
    return false;
  }
}
formata_cpf_cnpj(valor) {
  let formatado: any = false;
  let valida = this.verifica_cpf_cnpj(valor);
  valor = valor.toString();
  valor = valor.replace(/[^0-9]/g, '');
  if (valida === 'CPF') {
    if (this.valida_cpf(valor)) {
      formatado = valor.substr(0, 3) + '.';
      formatado += valor.substr(3, 3) + '.';
      formatado += valor.substr(6, 3) + '-';
      formatado += valor.substr(9, 2) + '';
    }
  } else if (valida === 'CNPJ') {
    if (this.valida_cnpj(valor)) {
      formatado = valor.substr(0, 2) + '.';
      formatado += valor.substr(2, 3) + '.';
      formatado += valor.substr(5, 3) + '/';
      formatado += valor.substr(8, 4) + '-';
      formatado += valor.substr(12, 14) + '';
      
    }
  }
  return formatado;
}

getFileFormat(src: string) {
  if(!src) return '';
  var ext = src.substring(src.lastIndexOf('.') + 1);
  switch(ext){
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'png':
    case 'webp':
    return 'image';
    case 'mpeg':
    case 'mp4':
    case 'mov':
    case 'webm':
    return 'video';
    default:
    return 'image'; // Por enquanto retorna imagem sempre que não for video até validar os formatos aceitáveis
  }
  
}
getOrientation(artwork: any) {
  if (typeof (artwork) != 'object') return '';
  if (Math.round((artwork.width / artwork.height) * 10) > 1) return 'vertical';
  if (Math.round((artwork.width / artwork.height) * 10) < 1) return 'horizontal';
  if (Math.round((artwork.width / artwork.height) * 10) == 1) return 'square';
  
}
timeSince(date: any) {
  let seconds = Math.floor(((new Date()).getTime() - (new Date(date)).getTime()) / 1000);
  let interval = Math.floor(seconds / 31536000);
  if (interval > 1) { return interval + " anos"; }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) { return interval + " meses"; }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) { return interval + " dias"; }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) { return interval + " horas"; }
  interval = Math.floor(seconds / 60);
  if (interval > 1) { return interval + " min"; }
  
  return Math.floor(seconds) + " seg.";
}



}
