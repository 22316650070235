<div>
  <form [formGroup]="formProfile" (ngSubmit)="updateProfile(formProfile)">
    <div class="row" style="background-image: url({{coverImage}});background-size: 100%;background-position: center; border-radius: 9px; margin: 0 20px; height: 23em;">
      <div class="col-md-6 d-flex align-items-end pb-3" style="background-color: rgb(255 255 255 / 33%);">
        <h1 class="font-weight-bold text-secondary display-4 mx-2">{{auth.userData.full_name}}</h1>
      </div>
      <div class="col-md-6 d-flex justify-content-end align-items-end pb-3" style="background-color: rgb(255 255 255 / 33%);">
        <button type="button" class="btn btn-primary" (click)="openModalUploadCoverImage()">Change Cover</button>
      </div>
    </div>
    <div class="">
      <div class="col-lg-12 px-3 mx-3">
        <h3 class="font-weight-bold text-secondary mt-5 mb-0">Profile Information</h3>
      </div>
      <div class="row d-md-flex justify-content-between align-items-center px-3 mx-2">
        <div class="col-lg-7 personal-information d-lg-flex justify-content-between align-items-center px-0">
          <div class="col-lg-10 mt-4 px-lg-2">
              <div class="d-flex input-group mb-2">
                <input (keyup)="onKey($event)" formControlName="full_name" type="text" class="form-control bg-light" id="inlineFormInputGroup" placeholder="Name" required>
                  <div class="input-group-prepend">
                      <button type="button" (click)="copyToClipboard()" class="input-group-text">
                          <img src="/assets/icons/trade-transfer.png" alt="">
                      </button>
                  </div>
              </div>
            <small class="px-lg-2">Your Alphaa URL: <span id="url" >{{urlBase}}/artist-detail/{{auth.userData.slug}}/</span></small>
          </div>
          <app-share-button></app-share-button>

          <div class="col-lg-4 d-flex px-lg-2 mt-3">
            <app-profile-avatar class="ml-3"></app-profile-avatar>
          </div>
        </div>
      </div>
    </div>
    <hr style="margin-top: 74px; margin-bottom: 29px;">
    <div class="">
      <div class="col-lg-12 px-3 mx-3">
        <h3 class="font-weight-bold text-secondary mt-4 mb-0">Contact Information</h3>
      </div>
      <div class="row d-flex align-items-center px-3 mx-1">
        <div class="col-lg-8 d-sm-flex px-0">
          <div class="col">
            <div class="mt-4 pt-1">
              <input class="border-0 bg-light form-control border-radius-default text-grey w-100" type="date" formControlName="found_date" placeholder="Found date" required>
            </div>
          </div>
          <div class="col">
            <div class="mt-4 pt-1">
              <ng-select formControlName="gender" placeholder="Select gender">
                <ng-option *ngFor="let gender of genres" [value]="gender.name">{{gender.name}}</ng-option>
              </ng-select>
            </div>
          </div>
          <!-- <div class="col">
            <div class="mt-4 pt-1">
              <input class="border-0 bg-light form-control border-radius-default text-grey w-100" formControlName="state" type="text" placeholder="State" required>
            </div>
          </div> -->
          <div class="col">
            <div class="mt-4 pt-1">
              <ng-select formControlName="race" placeholder="Select race">
                <ng-option *ngFor="let race of races" [value]="race.name">{{race.name}}</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr style="margin-top: 74px; margin-bottom: 29px;">
    <div class="">
      <div class="col-lg-12 px-3 mx-3">
        <h3 class="font-weight-bold text-secondary mt-4 mb-0">Contact Information</h3>
      </div>
      <div class="row d-flex align-items-center px-3 mx-1">
        <div class="col-lg-4">
          <div class="mt-4 pt-1">
            <input class="border-0 bg-light form-control border-radius-default w-100" formControlName="email" type="email" placeholder="Your email" required>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="mt-4 pt-1">
            <input class="border-0 bg-light form-control border-radius-default text-grey w-100" formControlName="phone" type="tel" placeholder="Your phone number" required>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="mt-4 pt-1">
            <input class="border-0 bg-light form-control border-radius-default text-grey w-100" formControlName="street" type="text" placeholder="Your Street" required>
          </div>
        </div>
        <div class="col-lg-8 d-sm-flex px-0">
          <div class="col">
            <div class="mt-4 pt-1">
              <input class="border-0 bg-light form-control border-radius-default text-grey w-100" formControlName="zip_code" type="text" placeholder="ZIP Code" required>
            </div>
          </div>
          <div class="col">
            <div class="mt-4 pt-1">
              <input class="border-0 bg-light form-control border-radius-default text-grey w-100" formControlName="city" type="text" placeholder="City" required>
            </div>
          </div>
          <!-- <div class="col">
            <div class="mt-4 pt-1">
              <input class="border-0 bg-light form-control border-radius-default text-grey w-100" formControlName="state" type="text" placeholder="State" required>
            </div>
          </div> -->
          <div class="col">
            <div class="mt-4 pt-1">
              <ng-select formControlName="country" placeholder="Select Country">
                <ng-option *ngFor="let country of countries" [value]="country.name">{{country.name}}</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="mt-5">
      <div class="col-lg-12 px-0 mt-4 pt-2">
        <h3 class="font-weight-bold text-secondary px-3 mx-3">Social</h3>
      <div class="row d-flex align-items-center px-3 mx-1">
        <div class="col-lg-8 d-sm-flex px-0">
          <div class="col">
            <div class="mt-4 pt-1">
              <input class="border-0 bg-light form-control border-radius-default text-grey w-100" formControlName="instagram" type="text" placeholder="Instagram" required>
            </div>
          </div>
          <div class="col">
            <div class="mt-4 pt-1">
              <input class="border-0 bg-light form-control border-radius-default text-grey w-100" formControlName="twitter" type="text" placeholder="Twitter" required>
            </div>
          </div>
          <div class="col">
            <div class="mt-4 pt-1">
              <input class="border-0 bg-light form-control border-radius-default text-grey w-100" formControlName="facebook" type="text" placeholder="Facebook" required>
            </div>
          </div>
        </div>
        <div class="col-lg-8 d-sm-flex px-0">
          <div class="col">
            <div class="mt-4 pt-1">
              <input class="border-0 bg-light form-control border-radius-default text-grey w-100" formControlName="whatsapp" type="text" placeholder="WhatsApp" required>
            </div>
          </div>
          <div class="col">
            <div class="mt-4 pt-1">
              <input class="border-0 bg-light form-control border-radius-default text-grey w-100" formControlName="linkedin" type="text" placeholder="Linkedin" required>
            </div>
          </div>
          <div class="col">
            <div class="mt-4 pt-1">
              <input class="border-0 bg-light form-control border-radius-default text-grey w-100" formControlName="blog" type="text" placeholder="Blog" required>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="my-5">
    <div class="">
      <div class="col-lg-12 px-3 mx-3 mb-2">
        <h3 class="font-weight-bold text-secondary mt-4 mb-0">Professional Information</h3>
      </div>
      <div class="row d-flex align-items-center px-3 mx-2">
        <!-- <div class="col-lg-8 d-sm-flex px-0">
          <div class="col">
            <div class="">
              <div ngbDropdown class="d-inline-block w-100">
                <p class="text-grey-link font-weight-light mb-1">Styles</p>
                <button type="button" class="btn text-left bg-light form-control border-radius-default text-grey w-100 d-flex justify-content-between align-items-center buttonDropDown cursor-pointer" [disabled]="artistId" id="dropdownBasic-artist" ngbDropdownToggle>
                  <h5 class="font-weight-light mb-0">Style</h5>
                  <div class="mt-1"><img src="/assets/icons/arrow.png" alt=""></div>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic-artist" class="boxMenuDropdrown">
                  <a class="dropDownContent text-grey cursor-pointer" style="border-radius: 7px;" ngbDropdownItem>Contemporary</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="mt-4 pt-1">
              <div ngbDropdown class="d-inline-block w-100">
                <button type="button" class="btn text-left bg-light form-control border-radius-default text-grey w-100 d-flex justify-content-between align-items-center buttonDropDown cursor-pointer" [disabled]="artistId" id="dropdownBasic-artist" ngbDropdownToggle>
                  <h5 class="font-weight-light mb-0">Style</h5>
                  <div class="mt-1"><img src="/assets/icons/arrow.png" alt=""></div>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic-artist" class="boxMenuDropdrown">
                  <a class="dropDownContent text-grey cursor-pointer" style="border-radius: 7px;" ngbDropdownItem>Minimalist</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="mt-4 pt-1">
              <input class="border-0 bg-light form-control border-radius-default text-grey w-100" type="text" placeholder="Other">
            </div>
          </div>
        </div>
        <div class="col-lg-3 d-sm-flex px-0">
          <div class="col px-0">
            <div class="mt-4 pt-1">
              <a class="nav-link cursor-pointer">
                <img src="/assets/icons/delete.svg">
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-8 d-sm-flex px-0">
          <div class="col">
            <div class="mt-4 pt-1">
              <div ngbDropdown class="d-inline-block w-100">
                <button type="button" class="btn text-left bg-light form-control border-radius-default text-grey w-100 d-flex justify-content-between align-items-center buttonDropDown cursor-pointer" [disabled]="artistId" id="dropdownBasic-artist" ngbDropdownToggle>
                  <h5 class="font-weight-light mb-0">Style</h5>
                  <div class="mt-1"><img src="/assets/icons/arrow.png" alt=""></div>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic-artist" class="boxMenuDropdrown">
                  <a class="dropDownContent text-grey cursor-pointer" style="border-radius: 7px;" ngbDropdownItem>Contemporary</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="mt-4 pt-1">
              <div ngbDropdown class="d-inline-block w-100">
                <button type="button" class="btn text-left bg-light form-control border-radius-default text-grey w-100 d-flex justify-content-between align-items-center buttonDropDown cursor-pointer" [disabled]="artistId" id="dropdownBasic-artist" ngbDropdownToggle>
                  <h5 class="font-weight-light mb-0">Style</h5>
                  <div class="mt-1"><img src="/assets/icons/arrow.png" alt=""></div>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic-artist" class="boxMenuDropdrown">
                  <a class="dropDownContent text-grey cursor-pointer" style="border-radius: 7px;" ngbDropdownItem>Other</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="mt-4 pt-1">
              <input class="border-0 bg-light form-control border-radius-default text-grey w-100" type="text" placeholder="Extra description here">
            </div>
          </div>
        </div>
        <div class="col-lg-3 d-sm-flex px-0">
          <div class="col px-0">
            <div class="mt-4 pt-1">
              <a class="nav-link cursor-pointer">
                <img src="/assets/icons/delete.svg">
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-12 d-sm-flex px-0">
          <div class="col px-0">
            <div class="mt-4 pt-1">
              <a class="nav-link cursor-pointer d-flex align-items-center">
                <img src="/assets/icons/button-add-blue.png" class="mx-1" style="width: 20px;">
                Add style
              </a>
            </div>
          </div>
        </div> -->
        <div class="col-lg-8 d-sm-flex">
          <div class="col px-0">
            <div class="mt-5">
              <ckeditor formControlName="about" class="w-100" [editor]="Editor" (ready)="onReady($event)"></ckeditor>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer row justify-content-between align-items-center bg-white pb-0 pt-4 mt-5 mx-0">
      <div class="pl-3">
        <!-- <button type="button" class="deleteAccount btn text-primary font-weight-regular py-3 px-0 cursor-pointer">Delete my account</button> -->
      </div>
      <div class="px-0">
        <!-- <button type="button" class="btn btn-lg btn-outline-primary mr-2 cursor-pointer mb-2" style="border: 2px solid;width: 154px;height: 50px;">Cancel</button> -->
        <button type="submit" class="saveChanges btn btn-lg btn-primary cursor-pointer mb-2 mr-2">Save changes</button>
      </div>
    </div>
  </form>
</div>