<div class="container-fluid px-3 mx-1">
    <div class="row">
        <div class="col">
            <div class="d-flex">
                <div class="">
                    <app-preview-artwork [preview]="transfer?.artwork_version?.artwork.preview" [img]="transfer?.artwork_version?.artwork.img" [styleBox]="'border-radius: 9px;width: 73px;object-fit: cover;height: 93px;'" [styleImage]="'border-radius: 9px;width: 73px;object-fit: cover;height: 93px;'"></app-preview-artwork>
                </div>
                <div class="ml-4">
                    <p class="text-grey-light font-weight-light mb-0 small">{{ transfer?.artwork_version?.artwork.digital ? 'Digital' : 'Physical' }} Artwork</p>
                    <h2 class="text-secondary font-weight-bold mb-0">{{ transfer?.artwork_version?.artwork.title }}</h2>
                    <p class="text-secondary font-weight-light">{{ transfer?.artwork_version?.artwork?.artist.full_name }}</p>
                </div>
            </div>
        </div>
        <div class="col-auto">
            <div class="d-sm-flex">
                <div class="mt-4">
                    <p class="text-secondary font-weight-bold mb-0">Trade initiated</p>
                    <p class="text-secondary">{{ helper.formatDateTime(transfer.created_at) }}</p>
                </div>
                <div class="mt-4 ml-sm-5">
                    <p class="text-secondary font-weight-bold mb-0">Price</p>
                    <p class="text-secondary">US$ {{ (transfer.amount/100).toFixed(2) }}</p>
                </div>
                <div class="mt-4 ml-sm-5">
                    <p class="text-secondary font-weight-bold mb-0">Total</p>
                    <p class="text-secondary">US$ {{ (transfer.total/100).toFixed(2) }}</p>
                </div>
                <div class="mt-4 ml-sm-5">
                    <p class="text-secondary font-weight-bold mb-0">Status</p>
                    <p class="text-primary">{{ status }}</p>
                </div>
                <div class="mt-4 ml-sm-5">

                    <button type="button" *ngIf="transfer.purchaser_id == auth.userData.id && ((transfer.type_trade == 'donation' && transfer.status == 1) || (transfer.total <= 0 && transfer.status == 1))" (click)="openModalBuyer(transfer)" class="btn btn-sm btn-primary w-100">Accept</button>

                    <button type="button" *ngIf="showPay && transfer.type_trade !== 'donation' && (transfer.status == 1 || transfer.status == 2) && transfer.total > 0" (click)="openModalBuyer(transfer)" class="btn btn-sm btn-primary w-100">Pay</button>
                    <!-- <button type="button" *ngIf="showDispute" (click)="openModalDispute(transfer, showPay)" class="btn btn-sm btn-outline-primary w-100 mt-2">Dispute</button> -->
                   
                   
                    <button type="button" *ngIf="showFeedBack && transfer.rating !== null" class="btn btn-sm btn-success w-100 mt-2">Feedback sent</button>
                   
                   
                    <div style="display: grid;">
                        <!-- <button type="button" class="btn btn-sm btn-primary btnArtwork">View Artwork</button> -->
                        <button type="button" *ngIf="transfer.purchaser_id == auth.userData.id && (showFeedBack && transfer.rating == null)" (click)="openModalFeedback(transfer)" class="btn btn-sm btn-outline-primary btnFeedback mt-2">Give feedback</button>
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>