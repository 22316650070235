<div class="container-fluid">
    <div class="row">
        <div id="bg-hand" class="col-sm-12 col-md-12 col-lg-6 d-flex flex-column align-items-center justify-content-center text-center">
            <h1 class="text-white">Create</h1>
            <p class="text-white px-sm-5 mx-4">
                A new solution for artists and institutions to easily issue NFTs attached to real and digital objects and sell directly to collectors.
            </p>
            <a *ngIf="!auth.isAuthenticated.getValue()" [routerLink]="['/auth/register']" class="btn btn-lg btn-primary d-flex align-items-center justify-content-center btn-home">Sign up</a>
            <a *ngIf="auth.isAuthenticated.getValue()" [routerLink]="['/home-creator']" class="btn btn-lg btn-primary d-flex align-items-center justify-content-center btn-home">Learn more</a>
        </div>
        <div id="bg-man" class="col-sm-12 col-md-12 col-lg-6 d-flex flex-column justify-content-center align-items-center text-center">
            <h1 class="text-white">Collect</h1>
            <p class="text-white px-sm-5 mx-4">
                Build Your Digital Vault of NFTs. Authenticate, trace and trade securely without the need of creating a complex metawallet.
            </p>
            <div>
                <a *ngIf="!auth.isAuthenticated.getValue()" [routerLink]="['/auth/register']" class="btn btn-lg btn-primary d-flex align-items-center justify-content-center btn-home">Sign up</a>
                <a *ngIf="auth.isAuthenticated.getValue()" [routerLink]="['/home-collect']" class="btn btn-lg btn-primary d-flex align-items-center justify-content-center btn-home">Learn more</a>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid section-two">
    <div class="row mt-5">
        <div class="col-lg-12 d-flex justify-content-center mt-4">
            <h2 class="text-display-4 text-center display-4">How it works</h2>
        </div>
    </div>
    <div class="row justify-content-center mt-sm-4 p-sm-3">
        <div class="col-sm-10 col-md-12 col-lg-4 pr-1 mb-5 pb-2">
            <div class="card planCards text-center p-5">
                <div class="card-body px-sm-0 pt-4">
                    <h6 class="text-primary font-weight-bold">Scan</h6>
                    <p class="card-text border-0 text-secondary">
                        Each physical edition is printed with a unique QR code that can be scanned to guarantee authenticity, verify ownership and transfer artwork between collectors.
                    </p>
                    <div class="mt-5">
                        <img style="width: 50%;" class="img-card-qr" src="https://www.gov.br/inss/pt-br/centrais-de-conteudo/imagens/qr-code-novo-fw-300x300-png/@@images/image" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-10 col-md-12 col-lg-4 pr-1 mb-5 pb-2">
            <div class="card planCards text-center p-5">
                <div class="card-body px-sm-0 pt-4">
                    <h6 class="text-primary font-weight-bold">Trade</h6>
                    <p class="card-text text-dark border-0">
                        Every collector who purchases a physical or digital edition receives a unique identifier, gaining access to the newly acquired work's digital token (NFT).
                    </p>
                    <div class="mt-5 qr-code">
                        <div class="d-flex align-items-center justify-content-center">
                            <img src="/assets/icons/transaction.png" alt="">
                            <p class="font-weight-light text-grey mb-0 ml-1">Transaction Data</p>
                        </div>
                        <h2 class="text-secondary text-display-4">A2K3••••••O918</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-10 col-md-12 col-lg-4 pr-1 mb-5 pb-2">
            <div class="card planCards text-center p-5">
                <div class="card-body px-sm-0 pt-4">
                    <h6 class="text-primary font-weight-bold">Track</h6>
                    <p class="card-text border-0 text-secondary">
                        The NFT or digital token system ensures that an artwork's digital and physical owner match in order to guarantee market value.
                    </p>
                    <div class="mt-5">
                        <img src="/assets/img/grafico-home-two.png" class="img-card-graphic" style="width: 100%">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 bg-grey mb-5">
    <div class="row mt-5">
        <div class="col-lg-12 text-center mt-5">
            <h2 class="mt-5 text-primary text-display-4 display-4">Easily Authenticate <br> for Artists and Collectors</h2>
            <div class="row d-flex justify-content-center">
                <div class="col-lg-5 mt-2">
                    <p class="text-grey-link font-weight-regular">
                        Purchase, authenticate, manage and trade physical and digital art assets. Alpha’a's signature Digital Vault is the future of art collecting.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="overflow-x mt-sm-3 pt-sm-3 d-flex align-items-start">
        <img class="m-2" style="border-radius: 9px;" src="/assets/img/oceans-home.png" alt="">
        <img class="m-2" style="border-radius: 9px;" src="/assets/img/church-home.png" alt="">
        <img class="m-2" style="border-radius: 9px;" src="/assets/img/arte-7.png" alt="">
        <img class="m-2" style="border-radius: 9px;" src="/assets/img/colors-home.png" alt="">
        <img class="m-2" style="border-radius: 9px;" src="/assets/img/oceans-home.png" alt="">
        <img class="m-2" style="border-radius: 9px;" src="/assets/img/church-home.png" alt="">
        <img class="m-2" style="border-radius: 9px;" src="/assets/img/arte-7.png" alt="">
        <img class="m-2" style="border-radius: 9px;" src="/assets/img/colors-home.png" alt="">
        <img class="m-2" style="border-radius: 9px;" src="/assets/img/oceans-home.png" alt="">
        <img class="m-2" style="border-radius: 9px;" src="/assets/img/church-home.png" alt="">
        <img class="m-2" style="border-radius: 9px;" src="/assets/img/arte-7.png" alt="">
        <img class="m-2" style="border-radius: 9px;" src="/assets/img/colors-home.png" alt="">
    </div>
</div>

<div class="container-fluid mb-5 pb-5 section-three">
    <div class="row d-flex justify-content-center">
        <h2 class="text-secondary text-display-4 mt-sm-5 pt-2 display-4">Who is it for?</h2>
    </div>
    <div class="row mt-5 d-flex justify-content-around">
        <div class="col-sm-6 col-md-4 mb-3  text-center">
            <div class="d-flex justify-content-center ">
                <img width="50%" src="/assets/img/home-creators.png" alt="">
            </div>
            <div class="mt-3">
                <h2 class="text-primary h1 font-weight-bold">Artists</h2>
                <p class="mt-3 text-secondary mx-5">
                    Issue NFTs, guarantee revenue on any secondary market transaction and view the value of your artworks in real-time.
                </p>
                <div class="d-flex justify-content-center align-items-center">
                    <a class="btn btn-lg btn-primary d-flex align-items-center justify-content-center cursor-pointer btn-home" [routerLink]="['/home-creator']">Learn more</a>
                </div>
            </div>

        </div>
        <div class="col-sm-6 col-md-4 mb-3  text-center">
            <div class="d-flex justify-content-center ">
                <img width="50%" src="/assets/img/home-institutions.png" alt="">
            </div>
            <div class="mt-3">
                <h2 class="text-primary h1 font-weight-bold">Institutions
                </h2>
                <p class="mt-3 text-secondary mx-5">
                    Help the artists you represent reach a global audience of buyers, with the ease of digital authentication and ownership-history verification.
                </p>
                <div class="d-flex justify-content-center align-items-center">
                    <a class="btn btn-lg btn-primary d-flex align-items-center justify-content-center cursor-pointer btn-home">Learn more</a>
                </div>
            </div>

        </div>
        <div class="col-sm-6 col-md-4 mb-3  text-center">
            <div class="d-flex justify-content-center ">
                <img width="50%" src="/assets/img/home-collector.png" alt="">
            </div>
            <div class="mt-3">
                <h2 class="text-primary h1 font-weight-bold">Collectors</h2>
                <p class="mt-3 text-secondary mx-5">
                    Buy directly from artists or trade with other collectors with guaranteed, digitally verified authenticity.
                </p>
                <div class="d-flex justify-content-center align-items-center">
                    <a [routerLink]="['/home-collect']" class="btn btn-lg btn-primary d-flex align-items-center justify-content-center cursor-pointer btn-home">Learn more</a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid section-four d-flex align-items-center mt-5">
    <div class="row">
        <div class="col-lg-5 d-flex justify-content-center" id="losangulo">
            <img src="/assets/icons/losango.svg" class="losangulo" alt="losangulo">
        </div>
        <div class="col-lg-7 d-flex flex-column justify-content-center text-light px-sm-5">
            <h2 class="display-4 text-white px-sm-5 mx-sm-5 font-weight-bold">What is an NFT?</h2>
            <p class="mt-4 px-sm-5 mx-sm-5">
                NFTs (non-fungible tokens) are digital art tokens supported by the blockchain. They are also the future of ensuring artwork authenticity, verifying transaction history and seamlessly trading between collectors. Alphaa.io's Digital Vault system helps artists
                issue digital-only NFTs and back physical-art editions with NFTs.
            </p>
            <div class="px-sm-5 mx-sm-5 mt-2 mb-5">
                <a class="btn btn-lg btn-primary d-flex align-items-center justify-content-center btn-home" [routerLink]="['/learn']">Learn more</a>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid bg-primary footer-home">
    <div class="row justify-content-center py-sm-4">
        <div class="col-lg-5 my-md-5 py-md-5">
            <div class="mt-xl-5 pt-xl-5">
                <h3 class="font-weight-bold text-white">Alpha’a.io</h3>
            </div>
            <div>
                <h4 class="text-white font-weight-light">NFTs for REAL</h4>
                <p class="text-white font-weight-light pr-sm-5 mr-sm-5">
                    Alphaa.io offers a unique, hybrid NFT certification model, creating digital clones associated with original and physical artworks. A simplified UX bypasses the complicated process of creating a meta wallet and allows artists and institutions to set resale
                    royalties for their pieces.
                </p>
            </div>
            <div class="pt-3">
                <a [routerLink]="['/about']" class="btn btn-lg btn-white border-0 text-primary font-weight-bold cursor-pointer d-flex align-items-center justify-content-center btn-home">About us</a>
            </div>
        </div>
        <div class="col-lg-4" id="alpha-platform">
            <img src="/assets/img/laptop.png" alt="notbook" class="laptop" style="width: 143.7%;margin-top: -23px;">
        </div>
    </div>
</div>